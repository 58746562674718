import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import actions from "./actions";
import alertActions from "../alert/actions";
import OrganisationHelper from "../../helpers/organisationHelper";
import { createNotification } from "../../components/uielements/notification";
import { reset } from "redux-form";

export function* fetchOrganisation() {
  yield takeEvery("FETCH_ORGANISATION", function* ({ payload }) {
    try {
      const { primary_user_id } = payload;
      const result = yield call(
        OrganisationHelper.fetchOrganisation,
        primary_user_id
      );
      if (result.data) {
        yield put({
          type: actions.FETCH_ORGANISATION_SUCCESS,
          payload: result,
        });
      }
    } catch (error) {
      yield put({
        type: actions.REMOVE_LOADER,
        payload: error,
      });

      yield put({
        type: alertActions.ADD_ERROR,
        payload: error,
      });
    }
  });
}

export function* fetchSingleOrganisation() {
  yield takeEvery("FETCH_SINGLE_ORGANISATION", function* ({ payload }) {
    try {
      const { id } = payload;
      const result = yield call(OrganisationHelper.fetchSingleOrganisation, id);
      if (result.data) {
        yield put({
          type: actions.FETCH_ORGANISATION_SUCCESS,
          payload: result,
        });
      }
    } catch (error) {}
  });
}

export function* updateOrganisation() {
  yield takeEvery("UPDATE_ORGANISATION", function* ({ payload }) {
    const { organisationInfo } = payload;
    try {
      const result = yield call(
        OrganisationHelper.updateOrganisation,
        organisationInfo
      );
      if (result.data) {
        yield put(reset("organisation"));
        yield put({
          type: actions.FETCH_SINGLE_ORGANISATION,
          payload: { id: organisationInfo.id },
        });

        yield put({
          type: alertActions.ADD_SUCCESS,
          payload: "Organisation Updated Successfully",
        });
      }
    } catch (error) {
      yield put(reset("organisation"));
      yield put({
        type: actions.REMOVE_LOADER,
        payload: error,
      });

      yield put({
        type: alertActions.ADD_ERROR,
        payload: error,
      });

      yield put({
        type: actions.FETCH_SINGLE_ORGANISATION,
        payload: { id: organisationInfo.id },
      });
    }
  });
}

export function* updateBilling() {
  yield takeEvery("UPDATE_BILLING", function* ({ payload }) {
    const { organisationInfo } = payload;

    try {

      const result = yield call(
        OrganisationHelper.updateOrganisation,
        organisationInfo
      );
      if (result.data) {
        yield put({
          type: actions.FETCH_ORGANISATION_SUCCESS,
          payload: result,
        });
        yield put({
          type: alertActions.ADD_SUCCESS,
          payload: "Billing Details Updated Successfully",
        });
      }
    } catch (error) {
      yield put({
        type: actions.REMOVE_LOADER,
        payload: error,
      });

      yield put({
        type: alertActions.ADD_ERROR,
        payload: error,
      });
      yield put({
        type: actions.FETCH_SINGLE_ORGANISATION,
        payload: { id: organisationInfo.id },
      });
    }
  });
}

export function* updateDeposit() {
  yield takeEvery("UPDATE_DEPOSIT", function* ({ payload }) {
    try {
      const { organisationInfo } = payload;

      const result = yield call(
        OrganisationHelper.updateOrganisation,
        organisationInfo
      );
      if (result.data) {
        yield put({
          type: alertActions.ADD_SUCCESS,
          payload: "Deposit Details Updated Successfully",
        });
      }
    } catch (error) {
      yield put({
        type: actions.REMOVE_LOADER,
        payload: error,
      });
      yield put({
        type: alertActions.ADD_ERROR,
        payload: error,
      });
    }
  });
}

export function* updateDirector() {
  yield takeEvery("UPDATE_DIRECTOR", function* ({ payload }) {
    const { directorInfo } = payload;

    try {
      const result = yield call(
        OrganisationHelper.updateDirector,
        directorInfo
      );
      if (result.data) {
        yield put({
          type: actions.FETCH_SINGLE_ORGANISATION,
          payload: { id: directorInfo.organisation_id },
        });
        yield put({
          type: alertActions.ADD_SUCCESS,
          payload: "Director Updated Successfully",
        });
      }
    } catch (error) {
      yield put({
        type: actions.REMOVE_LOADER,
        payload: error,
      });
      yield put({
        type: actions.FETCH_SINGLE_ORGANISATION,
        payload: { id: directorInfo.organisation_id },
      });
      yield put({
        type: alertActions.ADD_ERROR,
        payload: error,
      });
    }
  });
}

export function* updateCreditCard() {
  yield takeEvery("UPDATE_CREDIT_CARD", function* ({ payload }) {
    try {
      const result = yield call(OrganisationHelper.updateCreditCard, payload);
      if (result.data) {
        yield put({
          type: actions.FETCH_ORGANISATION_SUCCESS,
          payload: result,
        });
        createNotification("success", "Credit Card Updated Successfully");
      }
    } catch (error) {
      yield put({
        type: actions.REMOVE_LOADER,
        payload: error,
      });
      if (error.data) {
        yield put({
          type: actions.FETCH_ORGANISATION_SUCCESS,
          payload: error,
        });
      }
      yield put({
        type: alertActions.ADD_ERROR,
        payload: error,
      });
    }
  });
}

export function* createCreditCard() {
  yield takeEvery("CREATE_CREDIT_CARD", function* ({ payload }) {
    try {
      const result = yield call(OrganisationHelper.createCreditCard, payload);
      if (result.data) {
        yield put({
          type: actions.FETCH_ORGANISATION_SUCCESS,
          payload: result,
        });

        createNotification("success", "Credit Card Added Successfully");
      }
    } catch (error) {
      yield put({
        type: actions.REMOVE_LOADER,
        payload: error,
      });
      if (error.data) {
        yield put({
          type: actions.FETCH_ORGANISATION_SUCCESS,
          payload: error,
        });
      }
      yield put({
        type: alertActions.ADD_ERROR,
        payload: error,
      });
    }
  });
}

export function* deleteCreditCard() {
  yield takeEvery("DELETE_CREDIT_CARD", function* ({ payload }) {
    try {
      const result = yield call(OrganisationHelper.deleteCreditCard, payload);
      if (result.data) {
        yield put({
          type: actions.FETCH_ORGANISATION_SUCCESS,
          payload: result,
        });
        createNotification("success", "Credit Card Deleted Successfully");
      }
    } catch (error) {
      yield put({
        type: actions.REMOVE_LOADER,
        payload: error,
      });
      if (error.data) {
        yield put({
          type: actions.FETCH_ORGANISATION_SUCCESS,
          payload: error,
        });
      }
      yield put({
        type: alertActions.ADD_ERROR,
        payload: error,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchOrganisation),
    fork(updateOrganisation),
    fork(updateDirector),
    fork(updateBilling),
    fork(updateDeposit),
    fork(fetchSingleOrganisation),
    fork(updateCreditCard),
    fork(createCreditCard),
    fork(deleteCreditCard),
  ]);
}
