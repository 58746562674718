import { apiHost, apiSocketHost } from "../../../config";
import React from "react";
import _ from "lodash";
import SuperFetch from "../../../helpers/superFetch";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { store } from "react-notifications-component";
import DataTable from "react-data-table-component";
import { getUser } from "../../../helpers/utility";
import InvoiceHelper from "../../../helpers/invoiceHelper";
import NumberFormat from "react-number-format";
import moment from "moment";
import InvoiceActions from "../../../redux/invoice/action";
import organisationAction from "../../../redux/organisation/actions";
import { connect } from "react-redux";

const daysDiff = (date) => {
  const splitted = date.split("-");
  const present = moment(new Date()).format("DD-MM-YYYY");
  const presentSplit = present.split("-");
  var due = moment([splitted[2], splitted[1], splitted[0]]); //due date
  var today = moment([presentSplit[2], presentSplit[1], presentSplit[0]]); //today's date

  let difference = today.diff(due, "days");

  return difference > 0 ? difference : "";
};

class InvoiceList extends React.Component {
  listRow = {
    reference: "",
    name: "",
    product: "",
    amount: "",
    paid_date: "",
    duo_date: "",
    status: "",
    created_at: "",
    checkedInvoices: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      arr: [],
      allData: [],
      delete_id: "",
      invoice_date: "",
      invoice_due_date: "",
      selectedRows: [],
      checkedInvoices: [],
      deleteType: "single",
      arrayIds: "",
      all_checked: false,
      file_url: "",
      file_upload: "",
      import_file_type: "",
      import_file_size: null,
      errors: {},
      formData: "",
      on_click: true,
      file_name: "",
      send_message: "",
      filter: "all",
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDeleteId = this.handleDeleteId.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleDueDate = this.handleDueDate.bind(this);
    this.handleBulkDelete = this.handleBulkDelete.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleBulk = this.handleBulk.bind(this);
    this.handleAllCheck = this.handleAllCheck.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleImport = this.handleImport.bind(this);
  }

  componentDidMount = () => {
    document.title = "Cloud Collect - Invoices";
    const { fetchCurrentInvoices, currentUser } = this.props;
    fetchCurrentInvoices();
    this.getImportData();
    this.props.fetchSingleOrganisation({
      id: currentUser.default_organisation_id,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.invoices !== this.props.invoices) {
      this.setState({ allData: nextProps.invoices, arr: nextProps.invoices });
    }
  };

  handleUploadFile(e) {
    let files = e.target.files[0];
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    let format = "";
    if (files.name) {
      this.setState({ file_name: files.name });
      format = files.name.split(".");
      if (format[1]) {
        this.setState({
          import_file_type: format[1],
          import_file_size: files.size,
          file_upload: files,
          formData: formData,
        });
      }
    }
  }

  validate() {
    let is_Error = false;
    const errors = {};
    if (this.state.import_file_type !== "xlsx") {
      is_Error = true;
      errors.file_type = 'File type should be "xlsx"';
    }
    this.setState({ errors: errors });
    return is_Error;
  }

  handleImport() {
    if (this.validate()) {
      return;
    } else {
      this.setState({ on_click: !this.state.on_click });
      if (this.state.on_click) {
        let formData = new FormData();
        formData.append("file", this.state.file_upload);
        let url = apiHost.fetchUrl + "/invoices/import/file";
        fetch(url, {
          method: "POST",
          body: formData,
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("id_token") || undefined,
          },
        })
          .then((response) => response.json())
          .then((result) => {
            $("#modal-org-invoice")
              .find('button[data-dismiss="modal"]')
              .click();

            if (result.error) {
              this.addErrorNotification(result.error);
            } else {
              this.addNotification();
              setTimeout(function () {
                window.location.reload(false);
              }, 5000);
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
      return;
    }
  }

  addNotification() {
    return store.addNotification({
      message: "Imported successfully.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }

  addErrorNotification(error) {
    return store.addNotification({
      message: error,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }

  handleBulk(e) {
    let stringIds = this.state.checkedInvoices.join(",");
    let ids = {
      ids: stringIds,
    };
    this.setState({
      deleteType: "bulk",
      arrayIds: ids,
    });
  }

  handleDownloadPdf = () => {
    const { selectedRows } = this.state;

    let uuids = "";
    for (let row of selectedRows) {
      if (uuids.length === 0) {
        uuids = row.uuid;
      } else {
        uuids += "," + row.uuid;
      }
    }

    if (uuids) {
      window.open(
        apiSocketHost.fetchUrl + "/invoice/multiple-pdf/" + uuids,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  handleCopy = async () => {
    const { selectedRows } = this.state;

    const selected = selectedRows.reduce(
      (obj, item) => Object.assign(obj, { ...item }),
      {}
    );

    await localStorage.setItem("copy_products", JSON.stringify(selected.items));

    this.props.history.push(`/invoice/add/product?products=copy`);
  };

  handleDate(e) {
    var date = null;
    if (e) {
      date = e;
    }
    this.setState(
      {
        invoice_date: date,
      },
      () => {
        this.handleFilterDate();
      }
    );
  }

  handleDueDate(e) {
    var date = null;
    if (e) {
      date = e;
    }
    this.setState(
      {
        invoice_due_date: date,
      },
      () => {
        this.handleFilterDate();
      }
    );
  }

  handleFilterDate() {
    let allData = JSON.parse(JSON.stringify(this.state.arr));
    let data = allData;
    if (this.state.invoice_date) {
      data = allData.filter((item) => {
        let filterDate = this.state.invoice_date;
        filterDate.setHours(0, 0, 0, 0);
        if (item.invoice_date !== null) {
          let invoiceDate = item.invoice_date.split("-");
          invoiceDate =
            invoiceDate[2] + "-" + invoiceDate[1] + "-" + invoiceDate[0];
          invoiceDate = new Date(invoiceDate);
          invoiceDate.setHours(0, 0, 0, 0);
          return invoiceDate.getTime() >= filterDate.getTime();
        }
      });
    }
    if (this.state.invoice_date && this.state.invoice_due_date) {
      data = allData.filter((item) => {
        let filterDate = this.state.invoice_date;
        let filterDateTo = this.state.invoice_due_date;
        if (item.invoice_date !== null) {
          let invoiceDate = item.invoice_date.split("-");
          invoiceDate =
            invoiceDate[2] + "-" + invoiceDate[1] + "-" + invoiceDate[0];
          invoiceDate = new Date(invoiceDate);
          invoiceDate.setHours(0, 0, 0, 0);
          return (
            invoiceDate.getTime() >= filterDate.getTime() &&
            invoiceDate.getTime() <= filterDateTo.getTime()
          );
        }
      });
    }

    this.setState({ allData: data });
  }

  handleBulkDelete = (e) => {
    const { fetchCurrentInvoices } = this.props;
    SuperFetch.post("/invoices/delete-bulk", this.state.arrayIds).then(
      (response) => {
        fetchCurrentInvoices();
        this.setState({
          checkedInvoices: [],
        });

        store.addNotification({
          message: response.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    );
  };

  handleAllCheck(e) {
    let tmp;
    if (e) {
      this.setState({
        all_checked: !this.state.all_checked,
      });
      if (!this.state.all_checked) {
        $(".custom-control-input").prop("checked", "true");
        this.state.arr.map((item) => {
          if (!this.state.checkedInvoices.includes(item.id)) {
            tmp = this.state.checkedInvoices;
            tmp.push(item.id.toString());
          } else {
            tmp = [];
            for (let id of this.state.checkedInvoices) {
              if (id !== item.id.toString()) tmp.push(id);
            }
          }
          this.setState({
            checkedInvoices: tmp,
          });
        });
      } else {
        tmp = [];
        this.setState({ checkedInvoices: tmp });
        $(".custom-control-input").prop("checked", "");
      }
    }
  }

  handleChecked(e) {
    let id = e.target.value;
    let tmp;
    if (!this.state.checkedInvoices.includes(id)) {
      tmp = this.state.checkedInvoices;
      tmp.push(id);
    } else {
      tmp = [];
      for (let item of this.state.checkedInvoices) {
        if (item !== id) tmp.push(item);
      }
    }
    this.setState({
      checkedInvoices: tmp,
    });
  }

  getImportData() {
    SuperFetch.get("/invoices/downloadExcel/xlsx").then((response) => {
      if (response.file_url) {
        this.setState({ file_url: response.file_url });
      }
    });
  }

  handleSelect(e) {
    if (e.target.value === "all") {
      this.setState({ filter: "all" });
    }
    if (e.target.value === "draft") {
      this.setState({ filter: "Draft" });
    }
    if (e.target.value === "approved") {
      this.setState({ filter: "Approved" });
    }
    if (e.target.value === "paid") {
      this.setState({ filter: "Paid" });
    }
    if (e.target.value === "voided") {
      this.setState({ filter: "Void" });
    }
    if (e.target.value === "awaiting") {
      this.setState({ filter: "Awaiting Payment" });
    }
    if (e.target.value === "plan") {
      this.setState({
        filter: "Payment Plan",
      });
    }
    if (e.target.value === "later") {
      this.setState({ filter: "Pay Later" });
    }
    if (e.target.value === "overdue") {
      this.setState({ filter: "Overdue" });
    }
    if (e.target.value === "archived") {
      SuperFetch.get("/invoices?status=Archived").then((response) => {
        this.setState({
          allData: response.data,
        });
      });
    }
    if (e.target.value) {
      let updatedList = this.state.arr;
      updatedList = updatedList.filter(function (item) {
        return (
          item.status.toLowerCase().search(e.target.value.toLowerCase()) !== -1
        );
      });
      this.setState({ allData: updatedList });
      if (e.target.value === "all") this.setState({ allData: this.state.arr });
    }
  }

  handleFilter(e) {
    if (e.target.value === "") {
      this.setState({ allData: this.state.arr });
    } else {
      let updatedList = this.state.arr;
      updatedList = updatedList.filter(function (item) {
        return (
          item.lead.full_name
            .toLowerCase()
            .search(e.target.value.toLowerCase()) !== -1 ||
          (item.invoice_number &&
            item.invoice_number
              .toString()
              .includes(e.target.value.toString())) ||
          (item.total && item.total.toString().search(e.target.value) !== -1)
        );
      });
      this.setState({ allData: updatedList });
    }
  }

  toSimpleDate(data) {
    let tmp = data.split(" ")[0];
    tmp = tmp.split("-");
    return tmp[2] + "-" + tmp[1] + "-" + tmp[0];
  }

  simpleFilterDate(data) {
    let tmp = data.split(".");
    return tmp[0] + "-" + tmp[1] + "-" + tmp[2];
  }

  handleRowClick = (invoice) => {
    let status = invoice.status;
    if (status === "Draft") {
      this.props.history.push("/invoice/edit/" + invoice.id);
    } else {
      this.props.history.push("/invoice/non-edit/" + invoice.id);
    }
  };

  handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    let ids = state.selectedRows.map((row) => row.id);
    this.setState({ checkedInvoices: ids, selectedRows: state.selectedRows });
  };

  handleDeleteId(id, e) {
    this.setState({
      delete_id: id,
      deleteType: "single",
    });
  }

  handleRemove = (e) => {
    const { fetchCurrentInvoices } = this.props;

    if (this.state.deleteType === "single") {
      SuperFetch.delete("/invoices/" + this.state.delete_id).then(
        (response) => {
          if (response) {
            fetchCurrentInvoices();
          }
        }
      );
    } else this.handleBulkDelete();
  };

  handleVoid = () => {
    const { fetchCurrentInvoices } = this.props;

    SuperFetch.post("/invoices/void-bulk", this.state.arrayIds).then(
      (response) => {
        if (response) {
          fetchCurrentInvoices();
        }
      }
    );
  };

  changeDate(data) {
    if (data) {
      let date = data.split("-");
      return date[0] + "/" + date[1] + "/" + date[2];
    }
    return;
  }

  handleSend = (email_only) => {
    let sendData = this.state.checkedInvoices;
    let postData = {
      message: this.state.send_message,
      include_pdf: "1",
      send_sms: "1",
    };

    if (email_only) {
      postData.send_sms = 0;
    }

    if (sendData.length === 0) return;
    else
      InvoiceHelper.fetchInvoiceSendBulk(sendData, postData).then((data) => {
        if (data) {
          this.setState({
            checkedInvoices: [],
          });
          $(".custom-control-input").prop("checked", "");
        }
      });
  };

  downloadAsPdfHandler = () => {
    const { invoices } = this.props;

    let selectedIds = this.state.checkedInvoices;

    for (let selectedId of selectedIds) {
      for (let invoice of invoices) {
        if (invoice.id === selectedId) {
          if (invoice.url) {
            window.open(apiSocketHost.fetchUrl + invoice.url, "_blank");
          }
        }
      }
    }
  };

  handleSort = (rows, field, direction) => {
    return _.orderBy(rows, [field], [direction]);
  };

  amountToFixed(e) {
    return (
      <NumberFormat
        value={parseFloat(e).toFixed(2)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
        renderText={(value) => <div>{value}</div>}
      />
    );
  }

  render() {
    const { filter, checkedInvoices } = this.state;
    const columns = [
      {
        name: "Reference",
        selector: "invoice_number",
        cell: (row) => (
          <div onClick={() => this.handleRowClick(row)}>
            {row.invoice_number}
          </div>
        ),
        sortable: true,
      },
      {
        name: "Name",
        selector: "lead.full_name",
        cell: (row) => (
          <div onClick={() => this.handleRowClick(row)}>
            {row.lead.full_name}
          </div>
        ),
        sortable: true,
      },
      {
        name: "Date",
        selector: "invoice_date_formatted",
        sortable: true,
      },

      {
        name: "Due",
        selector: "due_date_formatted",
        sortable: true,
        cell: (row) => <div>{row.due_date}</div>,
      },
      {
        name: "Paid Date",
        selector: "payment_date_formatted",
        sortable: true,
        cell: (row) => <div>{row.status === "Paid" && row.payment_date}</div>,
      },
      {
        name: "Days Overdue",
        selector: "overdue_days",
        cell: (row) => (
          <div>{row.status === "Overdue" ? daysDiff(row.due_date) : ""}</div>
        ),
        sortable: true,
      },
      {
        name: "Amount",
        selector: "total",
        cell: (row) => <div>{this.amountToFixed(row.total)}</div>,
        sortable: true,
      },
      {
        name: "Outstanding",
        selector: "balance",
        cell: (row) => <div>{this.amountToFixed(row.balance)}</div>,
        sortable: true,
      },

      {
        name: "Status",
        selector: "status",
        cell: (row) => (
          <div>{row.status === "Plan" ? "Payment Plan" : row.status}</div>
        ),
        sortable: true,
      },
    ];

    const customStyles = {
      headCells: {
        style: {
          fontSize: "14px",
          fontWeight: "600",
        },
      },
    };

    return (
      <div className="main-content-inner">
        <div className="row">
          <div className="col-12 mt-3">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-9 col-sm-12 c-buttons">
                    <div className="input-group">
                      <button
                        type="button"
                        onClick={() => this.props.history.push("/invoice/add")}
                        className="btn btn-secondary"
                        id="button-float-left"
                        style={{ marginRight: 15 }}
                      >
                        New Invoice
                      </button>
                      <div className="">
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="button-float-left"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Action
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            onClick={() => this.handleSend(true)}
                            className="dropdown-item"
                            href="javascript:void(0);"
                          >
                            Send Email
                          </a>
                          {this.props.organisationData &&
                            this.props.organisationData.stripe_customer_id && (
                              <a
                                onClick={() => this.handleSend(false)}
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                Send Email and SMS
                              </a>
                            )}
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={this.handleBulk.bind(this)}
                            data-toggle="modal"
                            data-target="#myModal"
                          >
                            Remove/Void
                          </a>
                          <a
                            className="dropdown-item"
                            href="#modal-org-invoice"
                            data-toggle="modal"
                            data-target="#modal-org-invoice"
                          >
                            Import
                          </a>
                          <a
                            className="dropdown-item"
                            href={this.state.file_url}
                          >
                            Export
                          </a>

                          <a
                            className="dropdown-item"
                            onClick={() => this.handleDownloadPdf()}
                            href="javascript:void(0);"
                          >
                            Download PDF
                          </a>

                          {checkedInvoices.length === 1 && (
                            <>
                              <a
                                className="dropdown-item"
                                onClick={this.handleCopy}
                                href="javascript:void(0);"
                              >
                                Copy
                              </a>
                            </>
                          )}

                          {/* <a
                            onClick={() => this.downloadAsPdfHandler()}
                            className="dropdown-item"
                            href="javascript:void(0);"
                          >
                            Print to PDF
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-sm-12">
                    <label class="sr-only" for="inlineFormInputGroup">
                      Username
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="input-group-text filter-span">Search</div>
                      </div>
                      <input
                        onChange={this.handleFilter.bind(this)}
                        className="form-control"
                        type="text"
                        placeholder="Name, Reference or Amount"
                        aria-label="Search"
                      />{" "}
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text filter-span">Status</div>
                      </div>
                      <select
                        onChange={this.handleSelect.bind(this)}
                        className="form-control"
                        id="exampleFormControlSelect1"
                        style={{ height: "44px" }}
                      >
                        <option value="all">All Active</option>
                        <option value="draft">Draft</option>
                        <option value="approved">Approved</option>
                        <option value="awaiting">Awaiting Payment</option>
                        <option value="later">Pay Later</option>
                        <option value="Plan">Payment Plan</option>
                        <option value="overdue">Overdue</option>
                        <option value="paid"> Paid</option>

                        {/* <option value="archived">Archived</option> */}

                        <option value="archived">Archived</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-12" id="hide-content">
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div className="input-group-text filter-span date-span">
                          Date From
                        </div>
                      </div>
                      <DatePicker
                        // placeholderText="Date From:"
                        className="form-control date-picker-input"
                        selected={this.state.invoice_date}
                        onChange={this.handleDate}
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </div>
                  </div>
                  <div
                    class="col-md-2 col-sm-12 margin_top_date"
                    id="hide-content"
                  >
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text filter-span date-span">
                          Date To
                        </div>
                      </div>
                      <DatePicker
                        className="form-control date-picker-input"
                        // placeholderText="Date To:"
                        selected={this.state.invoice_due_date}
                        onChange={this.handleDueDate}
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </div>
                  </div>
                </div>
                <br />

                <div id="table-invoices" className="single-table">
                  <div className="">
                    <DataTable
                      noHeader
                      overflowY
                      highlightOnHover
                      responsive={true}
                      title=""
                      columns={columns}
                      data={
                        filter
                          ? filter === "all"
                            ? this.state.allData &&
                              this.state.allData.filter(
                                (invoice) => filter && invoice.status !== "Void"
                              )
                            : this.state.allData &&
                              this.state.allData.filter(
                                (invoice) => filter && invoice.status === filter
                              )
                          : this.state.allData || []
                      }
                      defaultSortField="Reference"
                      onRowClicked={this.handleRowClick}
                      selectableRows
                      Clicked
                      pointerOnHover={true}
                      onSelectedRowsChange={this.handleChange}
                      customStyles={customStyles}
                      pagination={true}
                      paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="myModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Are you sure want Archive?</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={this.handleRemove.bind(this)}
                  data-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="myModalForVoid">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Are you sure want to void?</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={this.handleVoid.bind(this)}
                  data-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal-org-invoice">
          <div
            className="modal-dialog modal-dialog-centered import_modal"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" style={{ margin: "auto" }}>
                  Import Invoices
                </h5>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="">
                    <div className="import-text" style={{ marginBottom: 30 }}>
                      <p>
                        1. Download{" "}
                        <a href="https://app.cloudcollect.io/invoices.xlsx">
                          Import Template
                        </a>
                        .<br />
                        2. Save data into the Import Template under relevant
                        columns.
                        <br />
                        3. Upload file using the Upload File button.
                        <br />
                      </p>
                    </div>
                    <div className="form-group-modal import_upload_file">
                      <label
                        style={{ margin: "auto" }}
                        htmlFor="upload_file"
                        className="btn btn_reject_color "
                      >
                        Upload File
                      </label>
                      <p className="text-secondary m-2">
                        {this.state.file_name && this.state.file_name}
                      </p>
                      <input
                        onChange={this.handleUploadFile.bind(this)}
                        style={{ display: "none" }}
                        id="upload_file"
                        type="file"
                        className="form-control form-non-edit-modal "
                        multiple
                      />
                    </div>
                    <div className="form-group-modal import_modal_error">
                      {this.state.errors.file_type !== "" && (
                        <span className="m-form__help w-100  text-danger">
                          {this.state.errors.file_type}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal-footer"
                style={{
                  borderTop: "1px #e9ecef",
                  padding: "2rem",
                  display: "block",
                }}
              >
                <div className="d-flex">
                  <button
                    onClick={this.handleImport.bind(this)}
                    type="button"
                    className="btn btn_reject_color  btn-secondary-modal btn_modal"
                  >
                    Import
                  </button>
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn_reject_color  btn-secondary-modal btn_modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { fetchCurrentInvoices } = InvoiceActions;
const { fetchSingleOrganisation } = organisationAction;

const mapDispatchToProps = { fetchCurrentInvoices, fetchSingleOrganisation };

const mapStateToProps = (state) => {
  return {
    currentUser: getUser(),
    invoices: state.Invoices.list,
    organisationData: state.Organisation.get("organisationData"),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
