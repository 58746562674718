import React from "react";
import SuperFetch from "../../../helpers/superFetch";
import CheckoutForm from "../../Customer/CheckoutForm";
import { Elements, StripeProvider } from "react-stripe-elements";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { store } from "react-notifications-component";

import { connect } from "react-redux";
import notificationAction from "../../../redux/notification/actions";
import "./preview.scss";
import LoginForm from "../../Customer/LoginModalForm";
import NumberFormat from "react-number-format";
import { renderTaxType } from "../../../_methods/commonMethods";
import { getUser } from "../../../helpers/utility";
import moment from "moment";

const date = new Date().setDate(new Date().getDate() + 14);

var days = 3; // Days you want to add
var today = new Date();
const first_payment = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);

class PreviewInvoice extends React.Component {
  token = {};

  constructor(props) {
    super(props);
    this.state = {
      payment_plan: {},
      formValues: {},
      all_data: {},
      is_payed: false,
      name: "",
      card: "",
      payNow_modal_close: false,
      payNow_modal_display: "none",
      cardChange_modal_close: false,
      cardChange_modal_display: "none",
      paylater_date: new Date(),
      first_payment: null,
      frequency: "Weekly",
      upfront: 0,
      amount: "",
      no_of_payments: 0,
      hide_paylater_button: false,
      includeDate: null,
      paymentPlan_max_date: date,
      show_paymentplan_button: false,
      max_term: "",
      newAmount: "",
      frequency_number: null,
      frequency_err: false,
      upfront_err: false,
      payLaterDate_err: true,
      status: "",
      termData: "",
      organisation: {},
      no_invoice_message: "",
      stripe_account_id: null,
      terms_conditions: null,
      err_message: "",
      err_message_plan: "",
      err_message_later: "",
      upfront_err_negative: false,
      auth_token: localStorage.getItem("auth_token"),
      frequencyOptions: ["Weekly", "Forthrightly", "Monthly"],
      no_of_payments_err: false,
      noOfPaymentOptions: [],
      loadPayNow: false,
      loadPayLater: false,
      loadPayScheduled: false,
      payment_plan: null,
      paymentSchedules: [],
      first_payment_err: false,
      payment_plan_err: false,
      loadCardChange: false,
      plan: null,
      changeCard: 0,
      payment_plan_id: 0,
    };
    this.handleDate = this.handleDate.bind(this);
    this.handleFirstPayment = this.handleFirstPayment.bind(this);
    this.handleInputUpfront = this.handleInputUpfront.bind(this);
    this.handleFrequency = this.handleFrequency.bind(this);
    this.handleNoOfPayments = this.handleNoOfPayments.bind(this);
  }

  handleUserInput3 = (e) => {
    let data = {};
    data[e.target.name] = e.target.value;
    this.setState(data);
  };

  handleKeyPress = (evt) => {
    if (
      (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
      evt.which > 57
    ) {
      evt.preventDefault();
    }
  };
  // number_of_payments

  handleInputUpfront(e) {
    this.setState({
      upfront: e.target.value,
      upfront_err: false,
    });
  }

  onChangeLoginForm = (e) => {
    let formValues = {};
    formValues[e.target.name] = e.target.value;
    this.setState(formValues);
  };

  addNotification() {
    return store.addNotification({
      message: "Logged in successfully.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }

  addNotificationError() {
    return store.addNotification({
      message: "Email or password is incorrect.",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }

  handleLoginSubmit = (values) => {
    SuperFetch.post("/login", values)
      .then(async (result) => {
        await localStorage.setItem("auth_token", result.token);
        this.setState({ auth_token: result.token });
        this.addNotification();
        // $("#modal-paynow").find('button[data-dismiss="modal"]').click();
        // $("#modal-payoptions").find('button[data-dismiss="modal"]').click();
        $("#modal-login").find('button[data-dismiss="modal"]').click();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((error) => {
        this.addNotificationError();
      });
  };

  getFlow() {
    SuperFetch.get("/flows").then((response) => {
      if (response && response.data)
        this.setState({
          max_term: response.data.maximum_terms,
          terms_conditions: response.data.terms_conditions,
        });

      if (response.data && response.data.offer_payment_plan === 1)
        this.setState({ show_paymentplan_button: true });
    });
  }

  handleFrequency(e) {
    let frequency = e.target.value;
    this.setState(
      { frequency, frequency_err: false, amount: "", no_of_payments: "" },
      () => {
        this.getNoOfPaymentsOptions();
      }
    );
  }

  getNoOfPaymentsOptions() {
    const { frequency, max_term } = this.state;
    let noOfPaymentOptions = [];

    if (!max_term) {
      noOfPaymentOptions = [1, 2, 3, 4];
    }

    if (max_term && frequency === "Weekly") {
      if (max_term.includes("week")) {
        let weeks = parseInt(max_term.replace("week", "").trim());
        for (let i = 1; i <= weeks; i++) {
          noOfPaymentOptions.push(i);
        }
      }
      if (max_term.includes("months")) {
        let months = parseInt(max_term.replace("months", "").trim());
        for (let i = 1; i <= months * 4; i++) {
          noOfPaymentOptions.push(i);
        }
      }
      if (max_term.includes("year")) {
        let years = parseInt(max_term.replace("year", "").trim());
        for (let i = 1; i <= years * 52; i++) {
          noOfPaymentOptions.push(i);
        }
      }
    }
    if (max_term && frequency === "Monthly") {
      if (max_term.includes("months")) {
        let months = parseInt(max_term.replace("months", "").trim());
        for (let i = 1; i <= months; i++) {
          noOfPaymentOptions.push(i);
        }
      }
      if (max_term.includes("year")) {
        let years = parseInt(max_term.replace("year", "").trim());
        for (let i = 1; i <= years * 12; i++) {
          noOfPaymentOptions.push(i);
        }
      }
    }

    if (max_term && frequency === "Forthrightly") {
      if (max_term.includes("week")) {
        let weeks = parseInt(max_term.replace("week", "").trim());
        if (weeks >= 2 && weeks < 4) {
          noOfPaymentOptions.push(1);
        }
        if (weeks === 4) {
          for (let i = 1; i <= 2; i++) {
            noOfPaymentOptions.push(i);
          }
        }
      }

      if (max_term.includes("months")) {
        let months = parseInt(max_term.replace("months", "").trim());
        for (let i = 1; i <= months * 2; i++) {
          noOfPaymentOptions.push(i);
        }
      }
      if (max_term.includes("year")) {
        let years = parseInt(max_term.replace("year", "").trim());
        for (let i = 1; i <= years * 28; i++) {
          noOfPaymentOptions.push(i);
        }
      }
    }

    this.setState({ noOfPaymentOptions });
  }

  handleNoOfPayments(e) {
    let no_of_payments = e.target.value;
    this.setState({ no_of_payments, no_of_payments_err: false }, () => {});
  }

  maxTermToNumber() {
    let term;
    let newTerm;
    if (this.state.max_term === "1 week") term = 1;
    if (this.state.max_term === "2 week") term = 2;
    if (this.state.max_term === "3 week") term = 3;
    if (this.state.max_term === "4 week") term = 4;
    if (this.state.max_term === "1 months") term = 4;
    if (this.state.max_term === "2 months") term = 8;
    if (this.state.max_term === "3 months") term = 12;
    if (this.state.max_term === "4 months") term = 16;
    if (this.state.max_term === "5 months") term = 20;
    if (this.state.max_term === "6 months") term = 24;
    if (this.state.max_term === "7 months") term = 28;
    if (this.state.max_term === "8 months") term = 32;
    if (this.state.max_term === "9 months") term = 36;
    if (this.state.max_term === "10 months") term = 40;
    if (this.state.max_term === "11 months") term = 44;
    newTerm = term / this.state.frequency_number;
    if (newTerm < 1) return null;
    if (newTerm > 1 || newTerm === 1) return newTerm;
  }

  getAmount() {
    let amount = 0;
    let total = this.state.all_data.balance;
    let week = this.state.no_of_payments;
    let upfront = this.state.upfront;
    if (total > 0 && week > 0) {
      amount = (total - upfront) / week;
      amount = (Math.round(amount * 100) / 100).toFixed(2);
    }

    return amount;
  }

  componentWillMount() {
    if (this.getAmount()) this.setState({ newAmount: this.getAmount() });
  }

  handleDate(e) {
    if (new Date(e).toDateString() === new Date().toDateString()) {
      this.setState({ payLaterDate_err: false });
    } else {
      this.setState({ payLaterDate_err: true });
    }
    this.setState({
      paylater_date: new Date(e),
    });
  }

  handleFirstPayment(e) {
    this.setState(
      {
        first_payment: new Date(e),
      },
      () => {
        this.updateSchedule();
      }
    );
  }

  rechangeDate(data) {
    if (data !== null) {
      let day = data.split("-");
      return this.addZero(day[1]) + "-" + this.addZero(day[0]) + "-" + day[2];
    }
  }

  changeDate(data) {
    let date = data.getDate();
    let month = data.getMonth() + 1;
    let year = data.getFullYear();
    return this.addZero(date) + "-" + this.addZero(month) + "-" + year;
  }

  addZero(day) {
    day = parseInt(day).toString();
    if (day.length === 1) day = "0" + day;
    return day;
  }

  getUser() {
    SuperFetch.get("/user").then((response) => {
      if (response)
        this.setState({
          // stripe_account_id: response.organisation
          //   ? response.organisation.stripe_account_id
          //   : null,
        });
    });
  }

  componentDidMount() {
    document.title = "Cloud Collect - Preview Invoice";
    this.getUser();
    this.getInvoice();

    // this.getFlow();
  }

  getInvoice = () => {
    let id = this.props.match.params.id;

    SuperFetch.get("/invoices/uuid/" + id).then((response) => {
      if (response.data.length === 0) {
        this.setState({ no_invoice_message: "There is no Invoice." });
        return;
      }

      if (response || response.data) {
        this.getResponseData(response.data);

        if (
          response.data.lead &&
          response.data.lead.organisation &&
          response.data.lead.organisation.flow
        ) {
          let flow = response.data.lead.organisation.flow;

          if (flow.maximum_terms && flow.maximum_terms.includes("week")) {
            let weeks = flow.maximum_terms.replace("week", "").trim();
            if (parseInt(weeks) >= 2) {
              let options = [];
              if (
                flow.payment_plan_frequency &&
                flow.payment_plan_frequency.length
              ) {
                if (flow.payment_plan_frequency.some((f) => f === "Weekly")) {
                  options.push("Weekly");
                }
                if (
                  flow.payment_plan_frequency.some((f) => f === "Forthrightly")
                ) {
                  options.push("Forthrightly");
                }
                this.setState({ frequencyOptions: options });
              }
            } else {
              if (
                flow.payment_plan_frequency &&
                flow.payment_plan_frequency.length
              ) {
                if (flow.payment_plan_frequency.some((f) => f === "Weekly")) {
                  this.setState({ frequencyOptions: ["Weekly"] });
                }
              }
            }
          } else if (
            flow.maximum_terms &&
            flow.maximum_terms.includes("months")
          ) {
            let options = [];
            if (
              flow.payment_plan_frequency &&
              flow.payment_plan_frequency.length
            ) {
              if (flow.payment_plan_frequency.some((f) => f === "Weekly")) {
                options.push("Weekly");
              }
              if (
                flow.payment_plan_frequency.some((f) => f === "Forthrightly")
              ) {
                options.push("Forthrightly");
              }
              if (flow.payment_plan_frequency.some((f) => f === "Monthly")) {
                options.push("Monthly");
              }
              this.setState({ frequencyOptions: options });
            }
          }

          this.setState({
            max_term: flow.maximum_terms,
            terms_conditions: flow.terms_conditions,
          });
          if (
            response.data.lead.organisation &&
            response.data.lead.organisation.flow.offer_payment_plan === 1
          )
            this.setState({ show_paymentplan_button: true });
        }

        this.getStripeAccountDetails();
      }

      this.setState({
        includeDate: new Date(this.rechangeDate(response.data.due_date)),
        status: response.data.status,
      });
      if (
        new Date(this.rechangeDate(response.data.due_date)) < new Date() ||
        new Date(this.rechangeDate(response.data.due_date)) === new Date()
      )
        this.setState({
          hide_paylater_button: true,
        });
      this.getId();

      this.getNoOfPaymentsOptions();
    });
  };

  getStripeAccountDetails = () => {
    const { all_data } = this.state;

    if (
      all_data &&
      all_data.lead &&
      all_data.lead.organisation.stripe_account_id
    ) {
      this.setState({
        stripe_account_id: all_data.organisation.stripe_account_id,
      });
    }
  };

  cardPayLater(token) {
    this.setState({ loadPayLater: true });

    if (token) {
      let paylater_date = this.changeDate(this.state.paylater_date);
      if (
        this.state.paylater_date.toDateString() === new Date().toDateString()
      ) {
        this.setState({
          payLaterDate_err: false,
        });
        return;
      }
      let data = {
        stripe_token: token.id,
        paylater_date: paylater_date,
      };
      SuperFetch.post("/invoices/paylater/" + this.state.all_data.id, data)
        .then((response) => {
          this.setState({ loadPayLater: false });

          if (response.data) {
            this.setState({ status: response.data.status });
          }
          store.addNotification({
            message: "Your payment is scheduled.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          $("#modal-paylater").find('button[data-dismiss="modal"]').click();
          // $("#modal-payoptions").show();
          $("#modal-payoptions").find('button[data-dismiss="modal"]').click();
        })
        .catch((error) => {
          this.setState({
            loadPayLater: false,
            err_message_later: error.error,
          });
        });
    }
  }

  validatePlan() {
    let upfront_err = false;
    let frequency_err = false;
    let payment_plan_err = false;
    let first_payment_err = false;
    let error = false;
    if (!this.state.payment_plan || this.state.payment_plan === "") {
      payment_plan_err = true;
      this.setState({ payment_plan_err: true });
    }

    if (this.state.payment_plan === "custom_payment_plan") {
      if (this.state.upfront === "") {
        upfront_err = true;
        this.setState({ upfront_err: true });
      }
      if (this.state.frequency === "") {
        frequency_err = true;
        this.setState({ frequency_err: true });
      }
    }
    if (!this.state.first_payment || this.state.first_payment === "") {
      first_payment_err = true;
      this.setState({ first_payment_err: true });
    }
    console.log(first_payment_err);
    console.log(payment_plan_err);
    if (upfront_err || frequency_err || first_payment_err || payment_plan_err)
      error = true;
    return error;
  }

  getId() {
    SuperFetch.get(
      "/invoices/by-reference/" +
        this.state.all_data.business_name +
        "/" +
        this.state.all_data.invoice_number
    ).then((response) => {});
  }

  cardPaymentPlan(token) {
    this.setState({ loadPayScheduled: true });

    if (token) {
      if (this.validatePlan()) {
        this.setState({ loadPayScheduled: false });

        return;
      } else {
        let data = {
          stripe_token: token.id,
          first_payment: this.changeDate(this.state.first_payment),
          amount: this.getAmount(),
          upfront: this.state.upfront,
          frequency: this.state.frequency,
          no_of_payments: this.state.no_of_payments,
          payment_plan: this.state.payment_plan,
          payment_schedules: this.state.paymentSchedules,
        };
        SuperFetch.post(
          "/invoices/payment-plan/" + this.state.all_data.id,
          data
        )
          .then((response) => {
            this.setState({ loadPayScheduled: false });

            if (response.data) {
              this.getResponseData(response.data);
              this.setState({ status: "Plan" });
              $("#modal-payplan").find('button[data-dismiss="modal"]').click();
            }
          })
          .catch((error) => {
            this.setState({
              loadPayScheduled: false,
              err_message_plan: error.error,
            });
          });
      }
    } else {
      this.setState({ loadPayScheduled: false });
    }
  }

  editCardPaymentPlan(token) {
    this.setState({ loadPayScheduled: true });

    if (token) {
      if (this.validatePlan()) {
        this.setState({ loadPayScheduled: false });

        return;
      } else {
        let data = {
          stripe_token: token.id,
          first_payment: this.changeDate(this.state.first_payment),
          amount: this.getAmount(),
          upfront: this.state.upfront,
          frequency: this.state.frequency,
          no_of_payments: this.state.no_of_payments,
          payment_plan: this.state.payment_plan,
          payment_schedules: this.state.paymentSchedules,
        };
        SuperFetch.post(
          "/invoices/payment-plan/" + this.state.payment_plan_id + "/update",
          data
        )
          .then((response) => {
            this.setState({ loadPayScheduled: false });

            if (response.data) {
              $("#modal-editpayplan")
                .find('button[data-dismiss="modal"]')
                .click();

              store.addNotification({
                message: "Payment Plan Updated Successfully.",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
            }
          })
          .catch((error) => {
            this.setState({
              loadPayScheduled: false,
              err_message_plan: error.error,
            });
          });
      }
    } else {
      this.setState({ loadPayScheduled: false });
    }
  }

  editPaymentPlan = () => {
    this.setState({ loadPayScheduled: true });

    if (this.validatePlan()) {
      this.setState({ loadPayScheduled: false });
      return;
    } else {
      let data = {
        first_payment: this.changeDate(this.state.first_payment),
        amount: this.getAmount(),
        upfront: this.state.upfront,
        frequency: this.state.frequency,
        no_of_payments: this.state.no_of_payments,
        payment_plan: this.state.payment_plan,
        payment_schedules: this.state.paymentSchedules,
      };
      SuperFetch.post(
        "/invoices/payment-plan/" + this.state.payment_plan_id + "/update",
        data
      )
        .then((response) => {
          this.setState({ loadPayScheduled: false });
          if (response.data) {
            $("#modal-editpayplan")
              .find('button[data-dismiss="modal"]')
              .click();

            store.addNotification({
              message: "Payment Plan Updated Successfully.",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loadPayScheduled: false,
            err_message_plan: error.error,
          });
        });
    }
  };

  cardAdd = (token) => {
    this.setState({ loadPayNow: true });
    if (token === "") {
      this.setState({
        payNow_modal_close: true,
        payNow_modal_display: "block",
      });
    }
    if (token) {
      this.setState({
        payNow_modal_close: true,
        payNow_modal_display: "none",
      });
      let data = {
        stripe_token: token.id,
      };
      SuperFetch.post("/invoices/paynow/" + this.state.all_data.id, data)
        .then((response) => {
          this.setState({ loadPayNow: false });

          if (response.success) {
            this.setState({
              status: "Paid",
              is_payed: true,
            });
            this.props.fetchNotification();
          }
          store.addNotification({
            message: "Payement is successfull.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          $("#modal-paynow").find('button[data-dismiss="modal"]').click();
          // $("#modal-payoptions").show();
          $("#modal-payoptions").find('button[data-dismiss="modal"]').click();
        })
        .catch((error) => {
          if (error.error) {
            store.addNotification({
              message: error.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
          this.setState({ loadPayNow: false, err_message: error.error });
        });
    }
    return;
  };

  cardChange = (token) => {
    this.setState({ loadCardChange: true });
    if (token === "") {
      this.setState({
        changeCard_modal_close: true,
        changeCard_modal_display: "block",
      });
    }
    if (token) {
      this.setState({
        changeCard_modal_close: true,
        changeCard_modal_display: "none",
      });
      let data = {
        stripe_token: token.id,
      };
      SuperFetch.post("/invoices/change-card/" + this.state.all_data.id, data)
        .then((response) => {
          this.setState({ loadCardChange: false });

          store.addNotification({
            message: "Card Changed successfull.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          $("#modal-cardChange").find('button[data-dismiss="modal"]').click();
        })
        .catch((error) => {
          if (error.error) {
            store.addNotification({
              message: error.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
          this.setState({ loadCardChange: false, err_message: error.error });
        });
    }
    return;
  };

  getResponseData(response) {
    let object = {
      id: response.id,
      notes: response.notes,
      lead_id: response.lead.id,
      lead: response.lead,
      organisation: response.lead.organisation,
      organisation_id: response.lead.organisation_id,
      product_id: response.lead.product_id,
      invoice_number: response.invoice_number,
      first_name: response.lead.first_name,
      last_name: response.lead.last_name,
      email: response.lead.email,
      mobile: response.lead.mobile,
      gst: response.gst,
      total: response.total,
      paid: response.paid,
      balance: response.balance,
      address: response.lead.organisation.address,
      business_name: response.lead.organisation.business_name,
      suburb: response.lead.organisation.suburb,
      state: response.lead.organisation.state,
      postcode: response.lead.organisation.postcode,
      invoice_date: response.invoice_date,
      due_date: response.due_date,
      net: parseFloat(response.total) - parseFloat(response.gst),
      items: response.items,
      amount: response.amount ? response.amount : 0,
      abn: response.lead.organisation.abn,
      logo: response.lead.organisation.logo_thumb_url,
      company_name: response.lead.company_name,
      payment_plan: response.payment_plan,
      latest_transaction: response.latest_transaction,
      status: response.status,
    };
    this.setState({ all_data: object });

    if (response.payment_plan) {
      this.setState(
        {
          payment_plan: response.payment_plan.payment_plan,
          no_of_payments: response.payment_plan.no_of_payments,
          upfront: response.payment_plan.upfront,
          first_payment: new Date(
            moment(response.payment_plan.first_payment, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            )
          ),
          amount: response.payment_plan.amount,
          payment_plan_id: response.payment_plan.id,
        },
        () => {
          this.updateSchedule();
        }
      );
    }

    this.getTermsConditions();
  }

  getTermsConditions() {
    SuperFetch.get(`/organisation/${this.state.all_data.organisation_id}/terms`)
      .then((response) => {
        this.setState({ termData: response.data });
      })
      .catch((error) => {
        throw error;
      });
  }

  ModalClose(e) {
    if (e) $("#modal-payoptions").hide();
  }

  handleClose() {
    $("#modal-payoptions").find('button[data-dismiss="modal"]').click();
  }

  handleKeypress(e) {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;
    const characterNumber = Number(characterCode);
    if (
      (characterNumber >= 0 && characterNumber <= 9) ||
      characterCode === "."
    ) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  }

  isExpired() {
    if (this.state.expiry_date) {
      if (new Date(this.rechangeDate(this.state.expiry_date)) < new Date()) {
        // this.setState({hide_accept_button: true})
        return true;
      }
    }
    return false;
  }

  amountToFixed(e) {
    return (
      <NumberFormat
        value={parseFloat(e).toFixed(2)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
        renderText={(value) => <span>{value}</span>}
      />
    );
  }

  handleInputChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.updateSchedule();
      }
    );
  };

  handleInputCheckbox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked ? 1 : 0,
    });
  };

  cancelPaymentPlan = () => {
    SuperFetch.post("/invoices/cancel-plan/" + this.state.payment_plan_id, [])
      .then((response) => {
        $("#modal-cancelplan").find('button[data-dismiss="modal"]').click();
        if (response || response.data) {
          this.getInvoice();
        }

        store.addNotification({
          message: "Plan cancelled successfull.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      })
      .catch((error) => {
        if (error.error) {
          store.addNotification({
            message: error.error,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
        this.setState({ loadCardChange: false, err_message: error.error });
      });
  };

  updateSchedule = () => {
    let paymentSchedules = [];

    let payment_plan = this.state.payment_plan;
    let first_payment = this.state.first_payment;
    let amount = this.state.all_data.balance;
    let frequency = this.state.frequency;
    let no_of_payments = 0;
    if (
      payment_plan &&
      payment_plan !== "" &&
      first_payment &&
      first_payment !== ""
    ) {
      if (payment_plan.includes("weekly")) {
        frequency = "Weekly";
        if (payment_plan.includes("two")) {
          no_of_payments = 2;
          let newAmount = amount / 2;
          let date = moment(first_payment, "DD-MM-YYYY");
          for (let i = 0; i < 2; i++) {
            paymentSchedules.push({
              date: date.format("DD MMM YYYY"),
              amount: parseFloat(newAmount).toFixed(2),
            });

            date = date.add(7, "days");
          }
        }
        if (payment_plan.includes("three")) {
          let newAmount = amount / 3;
          no_of_payments = 3;
          let date = moment(first_payment, "DD-MM-YYYY");

          for (let i = 0; i < 3; i++) {
            paymentSchedules.push({
              date: date.format("DD MMM YYYY"),
              amount: parseFloat(newAmount).toFixed(2),
            });
            date = date.add(7, "days");
          }
        }
        if (payment_plan.includes("four")) {
          let newAmount = amount / 4;
          let date = moment(first_payment, "DD-MM-YYYY");
          no_of_payments = 4;

          for (let i = 0; i < 4; i++) {
            paymentSchedules.push({
              date: date.format("DD MMM YYYY"),
              amount: parseFloat(newAmount).toFixed(2),
            });
            date = date.add(7, "days");
          }
        }
      }
      if (payment_plan.includes("fortnightly")) {
        frequency = "Fortnightly";
        if (payment_plan.includes("two")) {
          no_of_payments = 2;

          let newAmount = amount / 2;
          let date = moment(first_payment, "DD-MM-YYYY");

          for (let i = 0; i < 2; i++) {
            paymentSchedules.push({
              date: date.format("DD MMM YYYY"),
              amount: parseFloat(newAmount).toFixed(2),
            });
            date = date.add(14, "days");
          }
        }
        if (payment_plan.includes("three")) {
          no_of_payments = 3;
          let newAmount = amount / 3;
          let date = moment(first_payment, "DD-MM-YYYY");

          for (let i = 0; i < 3; i++) {
            paymentSchedules.push({
              date: date.format("DD MMM YYYY"),
              amount: parseFloat(newAmount).toFixed(2),
            });
          }
        }
        if (payment_plan.includes("four")) {
          no_of_payments = 4;
          let newAmount = amount / 4;
          let date = moment(first_payment, "DD-MM-YYYY");

          for (let i = 0; i < 4; i++) {
            paymentSchedules.push({
              date: date.format("DD MMM YYYY"),
              amount: parseFloat(newAmount).toFixed(2),
            });
            date = date.add(14, "days");
          }
        }
      }
      if (payment_plan.includes("monthly")) {
        frequency = "Monthly";

        if (payment_plan.includes("two")) {
          no_of_payments = 2;
          let newAmount = amount / 2;
          let date = moment(first_payment, "DD-MM-YYYY");

          for (let i = 0; i < 2; i++) {
            paymentSchedules.push({
              date: date.format("DD MMM YYYY"),
              amount: parseFloat(newAmount).toFixed(2),
            });
            date = date.add(1, "months");
          }
        }
        if (payment_plan.includes("three")) {
          no_of_payments = 3;
          let newAmount = amount / 3;
          let date = moment(first_payment, "DD-MM-YYYY");

          for (let i = 0; i < 3; i++) {
            paymentSchedules.push({
              date: date.format("DD MMM YYYY"),
              amount: parseFloat(newAmount).toFixed(2),
            });
            date = date.add(1, "months");
          }
        }
        if (payment_plan.includes("four")) {
          no_of_payments = 4;
          let newAmount = amount / 4;
          let date = moment(first_payment, "DD-MM-YYYY");

          for (let i = 0; i < 4; i++) {
            paymentSchedules.push({
              date: date.format("DD MMM YYYY"),
              amount: parseFloat(newAmount).toFixed(2),
            });
            date = date.add(1, "months");
          }
        }
      }
    }
    this.setState({
      paymentSchedules,
      no_of_payments,
      frequency,
    });
  };

  render() {
    let organisation =
      this.state.all_data && this.state.all_data.lead
        ? this.state.all_data.lead.organisation
        : null;

    let selectedPlan = this.state.payment_plan;
    let firstPayment = this.state.first_payment
      ? this.changeDate(this.state.first_payment)
      : null;

    this.isExpired();
    const { stripe_account_id } = this.state;
    const paymentsPlan = [];
    const flow =
      this.state.all_data && this.state.all_data.organisation
        ? this.state.all_data.organisation.flow
        : null;
    const paymentDates = [];

    if (flow) {
      if (
        flow.two_weekly_payments == "Enable" ||
        flow.two_weekly_payments == "Auto Accept"
      ) {
        paymentsPlan.push({
          label: "2 weekly payments",
          value: "two_weekly_payments",
        });
      }
      if (
        flow.three_weekly_payments == "Enable" ||
        flow.three_weekly_payments == "Auto Accept"
      ) {
        paymentsPlan.push({
          label: "3 weekly payments",
          value: "three_weekly_payments",
        });
      }
      if (
        flow.four_weekly_payments == "Enable" ||
        flow.four_weekly_payments == "Auto Accept"
      ) {
        paymentsPlan.push({
          label: "4 weekly payments",
          value: "four_weekly_payments",
        });
      }
      if (
        flow.two_fortnightly_payments == "Enable" ||
        flow.two_fortnightly_payments == "Auto Accept"
      ) {
        paymentsPlan.push({
          label: "2 fortnightly payments",
          value: "two_fortnightly_payments",
        });
      }
      if (
        flow.three_fortnightly_payments == "Enable" ||
        flow.three_fortnightly_payments == "Auto Accept"
      ) {
        paymentsPlan.push({
          label: "3 fortnightly payments",
          value: "three_fortnightly_payments",
        });
      }
      if (
        flow.four_fortnightly_payments == "Enable" ||
        flow.four_fortnightly_payments == "Auto Accept"
      ) {
        paymentsPlan.push({
          label: "4 fortnightly payments",
          value: "four_fortnightly_payments",
        });
      }
      if (
        flow.two_monthly_payments == "Enable" ||
        flow.two_monthly_payments == "Auto Accept"
      ) {
        paymentsPlan.push({
          label: "2 monthly payments",
          value: "two_monthly_payments",
        });
      }
      if (
        flow.three_monthly_payments == "Enable" ||
        flow.three_monthly_payments == "Auto Accept"
      ) {
        paymentsPlan.push({
          label: "3 monthly payments",
          value: "three_monthly_payments",
        });
      }
      if (
        flow.four_monthly_payments == "Enable" ||
        flow.four_monthly_payments == "Auto Accept"
      ) {
        paymentsPlan.push({
          label: "4 monthly payments",
          value: "four_monthly_payments",
        });
      }
      if (
        (flow.custom_payment_plan == "Enable" ||
          flow.custom_payment_plan == "Auto Accept") &&
        flow.payment_plan_frequency &&
        flow.payment_plan_frequency.length
      ) {
        paymentsPlan.push({
          label: "Custom payment plan",
          value: "custom_payment_plan",
        });
      }
      let first_payment_within = flow.first_payment_within
        ? flow.first_payment_within
        : 7;

      if (first_payment_within) {
        let todayDate = moment().add(1, "days");
        paymentDates.push({
          value: todayDate.valueOf(),
          label: todayDate.format("DD MMM YYYY"),
        });
        todayDate = moment().add(parseInt(flow.first_payment_within), "days");
        paymentDates.push({
          value: todayDate.valueOf(),
          label: todayDate.format("DD MMM YYYY"),
        });
        // for (let i = 0; i < parseFloat(flow.first_payment_within); i++) {
        //   paymentDates.push({
        //     value: todayDate.format("DD-MM-YYYY"),
        //     label: todayDate.format("DD MMM YYYY"),
        //   });
        //   todayDate.add(1, "days");
        // }
      }
    }

    // if ()
    return (
      <>
        <div className="main-content">
          <div className="main-content-inner-preview">
            {this.state.status === "Approved" ||
            this.state.status === "Awaiting Payment" ? (
              <div className="row no-gutters">
                <div className="col-lg-6 mt-3" id="card-customer-invoice">
                  <div className="card">
                    <div className="card-body" id="form-status-pending">
                      <div className="row">
                        <div className="col-9" id="status-text">
                          <h5>AWAITING PAYMENT</h5>
                        </div>
                        <div className="col-3">
                          {stripe_account_id && (
                            <button
                              type="button"
                              className="btn btn-success"
                              data-toggle="modal"
                              data-target="#modal-payoptions"
                              id="button-action"
                            >
                              Payment Options
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.status === "Plan" && (
              <div class="row no-gutters">
                <div class="col-lg-6 mt-3" id="card-customer-invoice">
                  <div class="card">
                    <div class="card-body" id="form-status">
                      <div class="row">
                        {this.state.all_data &&
                          this.state.all_data.latest_transaction && (
                            <>
                              <div class="col-6" id="status-text">
                                <h5>PAYMENT SCHEDULED</h5>
                              </div>
                              <div className="col-3">
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  data-toggle="modal"
                                  data-target="#modal-cancelplan"
                                  id="button-action"
                                >
                                  Cancel Plan
                                </button>
                              </div>
                              <div className="col-3">
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  data-toggle="modal"
                                  data-target="#modal-cardChange"
                                  id="button-action"
                                >
                                  Payment Method
                                </button>
                              </div>
                            </>
                          )}

                        {this.state.all_data.payment_plan &&
                          this.state.all_data.payment_plan.status ===
                            "Pending" && (
                            <>
                              <div class="col-3" id="status-text">
                                <h5>PAYMENT PLAN</h5>
                              </div>
                              <div className="col-3">
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  data-toggle="modal"
                                  data-target="#modal-editpayplan"
                                  id="button-action"
                                >
                                  Change Plan
                                </button>
                              </div>
                              <div className="col-3">
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  data-toggle="modal"
                                  data-target="#modal-cancelplan"
                                  id="button-action"
                                >
                                  Cancel Plan
                                </button>
                              </div>
                              <div className="col-3">
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  data-toggle="modal"
                                  data-target="#modal-cardChange"
                                  id="button-action"
                                >
                                  Payment Method
                                </button>
                              </div>
                            </>
                          )}

                        {this.state.all_data.payment_plan &&
                          this.state.all_data.payment_plan.status ===
                            "Accepted" &&
                          !this.state.all_data.latest_transaction && (
                            <>
                              <div class="col-6" id="status-text">
                                <h5>PAYMENT PLAN</h5>
                              </div>
                              <div className="col-3">
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  data-toggle="modal"
                                  data-target="#modal-cancelplan"
                                  id="button-action"
                                >
                                  Cancel Plan
                                </button>
                              </div>
                              <div className="col-3">
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  data-toggle="modal"
                                  data-target="#modal-cardChange"
                                  id="button-action"
                                >
                                  Payment Method
                                </button>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.isExpired() && (
              <div class="row no-gutters">
                <div class="col-lg-6 mt-3" id="card-customer-invoice">
                  <div class="card">
                    <div class="card-body" id="form-status">
                      <div class="row">
                        <div class="col-12" id="status-text">
                          <h5>PROPOSAL EXPIRED</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.status === "Overdue" && (
              <div class="row no-gutters">
                <div class="col-lg-6 mt-3" id="card-customer-invoice">
                  <div class="card">
                    <div class="card-body" id="form-status">
                      <div class="row">
                        <div class="col-9" id="status-text">
                          <h5>OVERDUE</h5>
                        </div>
                        <div class="col-3 pull-right" id="content-pull-right">
                          {stripe_account_id && (
                            <button
                              type="button"
                              class="btn btn-success"
                              data-toggle="modal"
                              data-target="#modal-payoptions"
                              id="button-action"
                            >
                              Payment Options
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.status === "Paid" && (
              <div className="row no-gutters">
                <div className="col-lg-6 mt-3" id="card-customer-invoice">
                  <div className="card">
                    <div className="card-body" id="form-status-paid">
                      <div className="row">
                        <div className="col-12" id="status-text">
                          <h5>PAID</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.status === "Later" && (
              <div className="row no-gutters">
                <div className="col-lg-6 mt-3" id="card-customer-invoice">
                  <div className="card">
                    <div className="card-body" id="form-status-later">
                      <div className="row">
                        <div className="col-9" id="status-text">
                          <h5>PAYMENT SCHEDULED</h5>
                        </div>
                        <div className="col-3">
                          {this.state.all_data &&
                            this.state.all_data.latest_transaction && (
                              <button
                                type="button"
                                className="btn btn-danger"
                                data-toggle="modal"
                                data-target="#modal-cardChange"
                                id="button-action"
                              >
                                Payment Method
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row no-gutters">
              <div className="col-lg-6 mt-3" id="card-customer-invoice">
                <div className="card">
                  <div className="card-body">
                    <div className="invoice-area">
                      <div className="invoice-head">
                        <div className="row">
                          <div className="col-6">
                            {this.state.status === "Draft" ? (
                              <h1>DRAFT INVOICE</h1>
                            ) : this.state.status === "Archived" ? (
                              <h1>ARCHIVED INVOICE</h1>
                            ) : (
                              <h1>INVOICE</h1>
                            )}
                            <h5>{this.state.all_data.business_name}</h5>
                            <p>
                              <small>ABN {this.state.all_data.abn}</small>
                            </p>
                            <br />
                          </div>
                          <div className="iv-right col-6 text-md-right">
                            {this.state.all_data.logo && (
                              <img
                                src={this.state.all_data.logo}
                                alt=""
                                style={{ width: "175px" }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 " id="form-content">
                          <h5>Invoice</h5>
                          <p>
                            Invoice Ref: #{this.state.all_data.invoice_number}
                          </p>
                          <p>
                            Invoice Date : {this.state.all_data.invoice_date}
                          </p>
                          <p>Due Date : {this.state.all_data.due_date}</p>
                        </div>
                        {this.state.all_data && this.state.all_data.lead && (
                          <div className="col-md-4 " id="form-content">
                            <h5>To</h5>
                            {/* <p class="text-capitalize">
                              {this.state.all_data.lead.company_name}
                            </p> */}
                            <p class="text-capitalize">
                              {this.state.all_data.lead.full_name}
                            </p>
                            {this.state.all_data.lead.address && (
                              <p class="text-capitalize">
                                {this.state.all_data.lead.address}
                              </p>
                            )}
                            <p class="text-capitalize">
                              {this.state.all_data.lead.suburb}{" "}
                              {this.state.all_data.lead.state}{" "}
                              {this.state.all_data.lead.postcode}
                            </p>
                          </div>
                        )}
                        {this.state.all_data &&
                          this.state.all_data.organisation && (
                            <div className="col-md-4 " id="form-content">
                              <h5>From</h5>
                              <p>
                                {this.state.all_data.organisation
                                  .business_name &&
                                  this.state.all_data.organisation
                                    .business_name}
                              </p>
                              {this.state.all_data.organisation.flow &&
                                parseInt(
                                  this.state.all_data.organisation.flow
                                    .address_detail
                                ) === 1 && (
                                  <>
                                    {this.state.all_data.organisation
                                      .address && (
                                      <p>
                                        {
                                          this.state.all_data.organisation
                                            .address
                                        }
                                      </p>
                                    )}

                                    <p>
                                      {this.state.all_data.organisation
                                        .suburb &&
                                        this.state.all_data.organisation
                                          .suburb}{" "}
                                      {this.state.all_data.organisation.state &&
                                        this.state.all_data.organisation
                                          .state}{" "}
                                      {this.state.all_data.organisation
                                        .postcode &&
                                        this.state.all_data.organisation
                                          .postcode}
                                    </p>
                                  </>
                                )}

                              {this.state.all_data.organisation.flow &&
                                parseInt(
                                  this.state.all_data.organisation.flow
                                    .mobile_detail
                                ) === 1 && (
                                  <>
                                    {this.state.all_data.organisation
                                      .contact_number && (
                                      <p>
                                        {
                                          this.state.all_data.organisation
                                            .contact_number
                                        }
                                      </p>
                                    )}
                                  </>
                                )}
                              {this.state.all_data.organisation.flow &&
                                parseInt(
                                  this.state.all_data.organisation.flow
                                    .email_detail
                                ) === 1 && (
                                  <>
                                    {this.state.all_data.organisation
                                      .contact_email && (
                                      <p>
                                        {
                                          this.state.all_data.organisation
                                            .contact_email
                                        }
                                      </p>
                                    )}
                                  </>
                                )}
                            </div>
                          )}
                      </div>
                      <div className="invoice-table table-responsive">
                        <table className="table table-bordered table-hover text-right">
                          <thead>
                            <tr>
                              <th className="table-text-left" id="column-1">
                                Description
                              </th>
                              <th className="table-text-right">Units</th>
                              <th
                                className="table-text-right"
                                width="125"
                                id="column-3"
                              >
                                Unit Price
                              </th>
                              <th
                                className="table-text-right"
                                width="125"
                                id="column-4"
                              >
                                Tax
                              </th>
                              <th
                                className="table-text-right"
                                width="125"
                                id="column-5"
                              >
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              {this.state.all_data &&
                                this.state.all_data.items &&
                                this.state.all_data.items.map((item) => {
                                  let total = item.quantity * item.amount;
                                  total = (
                                    Math.round(total * 100) / 100
                                  ).toFixed(2);
                                  return (
                                    <tr>
                                      <td
                                        className="table-text-left"
                                        id="column-1"
                                      >
                                        {item.description}
                                      </td>
                                      <td
                                        className="table-text-right"
                                        id="column-2"
                                      >
                                        {item.quantity}
                                      </td>
                                      <td
                                        className="table-text-right"
                                        id="column-3"
                                      >
                                        {item.amount
                                          ? this.amountToFixed(item.amount)
                                          : "0.00"}
                                      </td>
                                      <td
                                        className="table-text-right"
                                        id="column-4"
                                      >
                                        {renderTaxType(item.gst_free)}
                                      </td>
                                      <td
                                        className="table-text-right"
                                        id="column-5"
                                      >
                                        {item.amount
                                          ? this.amountToFixed(total)
                                          : "0.00"}
                                      </td>
                                    </tr>
                                  );
                                })}

                              <tr className="mobile-bold">
                                <td colSpan="5">
                                  <span>Sub-Total</span>&nbsp;
                                  <div className="total_position">
                                    {this.state.all_data.net
                                      ? this.amountToFixed(
                                          this.state.all_data.net
                                        )
                                      : "0.00"}
                                  </div>
                                </td>
                              </tr>
                              <tr className="mobile-bold">
                                <td colSpan="5">
                                  <span>Total GST</span>&nbsp;
                                  <div className="total_position">
                                    {this.state.all_data.gst
                                      ? this.amountToFixed(
                                          this.state.all_data.gst
                                        )
                                      : "0.00"}
                                  </div>
                                </td>
                              </tr>
                              <tr className="mobile-bold">
                                <td colSpan="5">
                                  <h5>
                                    <span>Total</span>&nbsp;
                                    <div className="total_position">
                                      {this.state.all_data.total
                                        ? this.amountToFixed(
                                            this.state.all_data.total
                                          )
                                        : "0.00"}
                                    </div>
                                  </h5>
                                </td>
                              </tr>

                              {this.state.all_data.balance !==
                                this.state.all_data.total && (
                                <>
                                  <tr>
                                    <td colSpan="5">
                                      <span>Payments</span>&nbsp;
                                      {this.state.all_data.paid ? (
                                        <NumberFormat
                                          value={parseFloat(
                                            this.state.all_data.paid
                                          ).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                          renderText={(value) => (
                                            <span>{value}</span>
                                          )}
                                        />
                                      ) : (
                                        "0.00"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="5">
                                      <h5>
                                        <span>Due</span>&nbsp;
                                        {this.state.all_data.balance ? (
                                          <NumberFormat
                                            value={parseFloat(
                                              this.state.all_data.balance
                                            ).toFixed(2)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            renderText={(value) => (
                                              <span>{value}</span>
                                            )}
                                          />
                                        ) : (
                                          "0.00"
                                        )}
                                      </h5>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </>
                          </tbody>
                        </table>
                        <p>
                          {this.state.all_data.notes &&
                            this.state.all_data.notes}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p id="cc-signature">
                        <strong>Powered by CLOUD COLLECT of Australia</strong>
                        <br />
                        Simplify Invoicing, Automate Debt Collection & Get Paid
                        <br />
                        <a href="https://www.cloudcollect.io">
                          www.cloudcollect.io
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal modal_add_item fade"
            tabindex="-1"
            id="modal-payoptions"
          >
            <div className="modal-dialog modal-dialog-centered" id="modal-pay">
              <div className="modal-content">
                <div className="modal-body">
                  <h5 className="modal-title">Payment Options</h5>
                  <hr />
                  {/* {!this.state.auth_token && (
                    <>
                      <div>
                        <p>
                          Existing Cloud Collect user?{" "}
                          <a
                            href="#modal-login"
                            data-toggle="modal"
                            data-target="#modal-login"
                          >
                            {" "}
                            Login
                          </a>
                          .
                        </p>
                      </div>
                    </>
                  )} */}
                  <br />
                  <div className="invoice-buttons text-center">
                    <button
                      type="button"
                      className="btn btn-success"
                      data-toggle="modal"
                      data-target="#modal-paynow"
                      onClick={this.handleClose.bind(this)}
                    >
                      Pay Now
                    </button>

                    {this.state.all_data.status !== "Overdue" && (
                      <>
                        {" "}
                        <br />
                        <button
                          type="button"
                          className="btn btn-success"
                          data-toggle="modal"
                          data-target="#modal-paylater"
                          onClick={this.handleClose.bind(this)}
                        >
                          Pay Later
                        </button>
                      </>
                    )}
                    <br />

                    {this.state.show_paymentplan_button &&
                      this.state.all_data &&
                      flow &&
                      parseFloat(this.state.all_data.balance) >=
                        parseFloat(flow.minimum_amount) && (
                        <button
                          type="button"
                          className="btn btn-success"
                          data-toggle="modal"
                          data-target="#modal-payplan"
                          onClick={this.handleClose.bind(this)}
                        >
                          Payment Plan
                        </button>
                      )}
                    <hr />
                    <button
                      type="button"
                      className="btn btn-secondary"
                      // className="close"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              "modal modal_add_item fade " +
              (!this.state.payNow_modal_close ? "" : "show")
            }
            id="modal-paynow"
            style={{ display: this.state.payNow_modal_display }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-pay_width"
              id="modal-pay"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <h5 className="modal-title">Pay Now</h5>
                  <hr />

                  {this.state.all_data &&
                    this.state.all_data.lead &&
                    this.state.all_data.lead.organisation && (
                      <>
                        <p style={{ textAlign: "center" }}>
                          {this.amountToFixed(this.state.all_data.balance)}{" "}
                          payment to{" "}
                          {this.state.all_data.lead.organisation.business_name}.
                        </p>
                      </>
                    )}
                  <div class="m-2"></div>

                  {/* {!this.state.auth_token && (
                    <>
                      <div>
                        <p>
                          Existing Cloud Collect user?{" "}
                          <a
                            href="#modal-login"
                            data-toggle="modal"
                            data-target="#modal-login"
                          >
                            {" "}
                            Login
                          </a>
                          .
                        </p>
                      </div>
                      <hr />
                    </>
                  )} */}
                  <StripeProvider
                    apiKey={
                      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
                      "pk_live_CeEh82TYTyUbPvi5CkQvLOSw00S4b8pC3R"
                    }
                  >
                    <Elements>
                      <CheckoutForm
                        load={this.state.loadPayNow}
                        termMessage={this.state.terms_conditions}
                        handleSubmit={this.cardAdd}
                        error={this.state.err_message}
                        btnclass={"btn-success mb-0"}
                      />
                    </Elements>
                  </StripeProvider>
                  {/*{(this.state.err_message !== "") &&*/}
                  {/*<p className="error_message">{this.state.err_message}</p>}*/}
                  <div class="m-2"></div>
                  <div className="invoice-buttons text-center">
                    <button
                      onClick={this.handleClose.bind(this)}
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              "modal modal_add_item fade " +
              (!this.state.cardChange_modal_close ? "" : "show")
            }
            id="modal-cardChange"
            style={{ display: this.state.cardChange_modal_display }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-pay_width"
              id="modal-pay"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <h5 className="modal-title">Change Card</h5>
                  <hr />

                  <StripeProvider
                    apiKey={
                      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
                      "pk_live_CeEh82TYTyUbPvi5CkQvLOSw00S4b8pC3R"
                    }
                  >
                    <Elements>
                      <CheckoutForm
                        load={this.state.loadCardChange}
                        termMessage={this.state.terms_conditions}
                        handleSubmit={this.cardChange}
                        error={this.state.err_message}
                      />
                    </Elements>
                  </StripeProvider>
                  {/*{(this.state.err_message !== "") &&*/}
                  {/*<p className="error_message">{this.state.err_message}</p>}*/}
                  <br />
                  <div class="m-2"></div>
                  <div className="invoice-buttons text-center">
                    <button
                      onClick={this.handleClose.bind(this)}
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal modal_add_item fade" id="modal-paylater">
            <div
              className="modal-dialog modal-dialog-centered modal-pay_width"
              id="modal-pay"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <h5 className="modal-title">Pay Later</h5>
                  <hr />

                  {this.state.all_data &&
                    this.state.all_data.lead &&
                    this.state.all_data.lead.organisation && (
                      <>
                        <p style={{ textAlign: "center" }}>
                          {this.amountToFixed(this.state.all_data.balance)}{" "}
                          scheduled payment to{" "}
                          {this.state.all_data.lead.organisation.business_name}.
                        </p>
                      </>
                    )}
                  <div class="m-2"></div>
                  {/* {!this.state.auth_token && (
                    <>
                      <div>
                        <p>
                          Existing Cloud Collect user?{" "}
                          <a
                            href="#modal-login"
                            data-toggle="modal"
                            data-target="#modal-login"
                          >
                            {" "}
                            Login
                          </a>
                          .
                        </p>
                      </div>
                      <hr />
                    </>
                  )} */}
                  <form>
                    <div className="form-group" style={{ maxHeight: "69px" }}>
                      <label htmlFor="">
                        Payment date (prior to due date{" "}
                        {this.state.all_data.due_date}):
                      </label>
                      <input
                        className={
                          "form-control " +
                          (this.state.payLaterDate_err ? "" : "border_red")
                        }
                        type="text"
                        aria-label="Search"
                      />
                      <DatePicker
                        placeholderText="Date "
                        selected={this.state.paylater_date}
                        minDate={new Date()}
                        maxDate={this.state.includeDate}
                        onChange={this.handleDate}
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </div>
                    <StripeProvider
                      apiKey={
                        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
                        "pk_live_CeEh82TYTyUbPvi5CkQvLOSw00S4b8pC3R"
                      }
                    >
                      <Elements>
                        <CheckoutForm
                          load={this.state.loadPayLater}
                          termMessage={this.state.terms_conditions}
                          handleSubmit={this.cardPayLater.bind(this)}
                          error={this.state.err_message_later}
                          btnclass={"btn-success mb-0"}
                          type={"later"}
                          flow={
                            this.state.all_data &&
                            this.state.all_data.lead &&
                            this.state.all_data.lead.organisation
                              ? this.state.all_data.lead.organisation.flow
                              : null
                          }
                        />
                      </Elements>
                    </StripeProvider>
                    {/*{(this.state.err_message_later !== "") &&*/}
                    {/*<p className="error_message">{this.state.err_message_later}</p>}*/}
                  </form>
                  <div class="m-2"></div>
                  <div className="invoice-buttons text-center">
                    <button
                      onClick={this.handleClose.bind(this)}
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade modal_add_item" id="modal-payplan">
            <div
              className="modal-dialog modal-dialog-centered modal-pay_width paymentplan"
              id="modal-pay"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <h5 className="modal-title">Payment Plan</h5>
                  <hr />
                  {this.state.all_data &&
                    this.state.all_data.lead &&
                    this.state.all_data.lead.organisation && (
                      <>
                        <p style={{ textAlign: "center" }}>
                          {this.amountToFixed(this.state.all_data.balance)}{" "}
                          payment plan with{" "}
                          {this.state.all_data.lead.organisation.business_name}.
                        </p>
                      </>
                    )}
                  <div class="m-2"></div>

                  {/* {!this.state.auth_token && (
                    <>
                      <div>
                        <p>
                          Existing Cloud Collect user?{" "}
                          <a
                            href="#modal-login"
                            data-toggle="modal"
                            data-target="#modal-login"
                          >
                            {" "}
                            Login
                          </a>
                          .
                        </p>
                      </div>
                      <hr />
                    </>
                  )} */}
                  <form>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label for="">Select Payment Plan</label>
                        <select
                          onChange={this.handleInputChange}
                          name="payment_plan"
                          className={
                            "form-control " +
                            (this.state.payment_plan_err ? "border_red" : "")
                          }
                          id="select"
                          value={this.state.payment_plan}
                        >
                          <option></option>
                          {paymentsPlan.map((plan) => (
                            <option value={plan.value}>{plan.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {this.state.payment_plan !== "" && (
                      <div
                        className="form-group row"
                        style={{ maxHeight: "69px" }}
                      >
                        <div className="col-sm-12">
                          <label for="">First Payment</label>
                          <input
                            className="form-control"
                            type="text"
                            aria-label="Search"
                          />
                          {paymentDates.length > 1 && (
                            <DatePicker
                              placeholderText="Date "
                              selected={this.state.first_payment}
                              minDate={new Date(paymentDates[0].value)}
                              maxDate={new Date(paymentDates[1].value)}
                              onChange={this.handleFirstPayment.bind(this)}
                              dateFormat={"dd/MM/yyyy"}
                            />
                          )}
                          {/* <select
                            onChange={this.handleInputChange}
                            name="first_payment"
                            className={
                              "form-control " +
                              (this.state.first_payment_err ? "border_red" : "")
                            }
                            id="select"
                            value={this.state.first_payment}
                          >
                            <option></option>
                            {paymentDates.map((plan) => (
                              <option value={plan.value}>{plan.label}</option>
                            ))}
                          </select> */}
                        </div>
                      </div>
                    )}

                    {this.state.payment_plan === "custom_payment_plan" && (
                      <>
                        {" "}
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label htmlFor="">Payment Amount</label>
                            <input
                              name="amount"
                              value={
                                this.getAmount() == 0.0
                                  ? "$XXX.XX"
                                  : this.getAmount()
                              }
                              type="number"
                              className="form-control"
                              aria-describedby=""
                              placeholder="$XXX.XX"
                              disabled
                            />
                          </div>
                          {flow.upfront_enabled === "1" && (
                            <div className="col-sm-6">
                              <label htmlFor="">Upfront Payment</label>
                              <input
                                type="number"
                                min="0"
                                step="0.01"
                                onChange={this.handleInputUpfront.bind(this)}
                                name="upfront"
                                onKeyPress={this.handleKeypress.bind(this)}
                                className={
                                  "form-control " +
                                  (this.state.upfront_err ||
                                  this.state.upfront_err_negative === true
                                    ? "border_red"
                                    : "")
                                }
                                placeholder=""
                              />
                            </div>
                          )}
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label htmlFor="">Frequency</label>
                            <select
                              onChange={this.handleFrequency.bind(this)}
                              name="frequency"
                              className={
                                "form-control " +
                                (this.state.frequency_err ? "border_red" : "")
                              }
                              id="select"
                              value={this.state.frequency}
                            >
                              <option></option>
                              {this.state.frequencyOptions.map(
                                (option, index) => {
                                  return (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                          <div className="col-sm-6">
                            <label htmlFor="">Number Of Payments</label>
                            <select
                              onChange={this.handleNoOfPayments.bind(this)}
                              value={this.state.no_of_payments}
                              name="no_of_payments"
                              className={
                                "form-control " +
                                (this.state.no_of_payments_err
                                  ? "border_red"
                                  : "")
                              }
                              id="select"
                            >
                              <option></option>
                              {this.state.noOfPaymentOptions.map(
                                (option, index) => {
                                  return (
                                    <option
                                      key={`payments${index}`}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                        </div>
                      </>
                    )}

                    <br />
                    {this.state.paymentSchedules &&
                      this.state.paymentSchedules.length > 0 && (
                        <>
                          <h6>Payment Schedule</h6>

                          {this.state.paymentSchedules.map((s, index) => (
                            <p style={{ fontSize: "14px" }}>
                              Payment #{index + 1} -{" "}
                              {this.amountToFixed(s.amount)} on {s.date}
                            </p>
                          ))}
                          <p style={{ fontSize: "14px" }}>
                            Total Paid -{" "}
                            {this.amountToFixed(this.state.all_data.balance)}
                          </p>
                          <br />
                        </>
                      )}
                    {/* <div className="form-group row">
                      <div className="col-sm-6 payment-plan">
                        <label htmlFor="">First Payment</label>
                        <input
                          className="form-control"
                          type="text"
                          aria-label="Search"
                        />
                        <DatePicker
                          placeholderText="Date "
                          selected={this.state.first_payment}
                          minDate={new Date(new Date().getTime() + 259200000)}
                          maxDate={new Date(date)}
                          onChange={this.handleFirstPayment.bind(this)}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div> */}
                    {/* <div className="form-group row">
                      <div className="col-sm-6 payment-plan">
                        <label>Number of payments:</label>
                        <input
                          name="number_of_payments"
                          type="text"
                          className="form-control"
                          onChange={(e) => this.handleUserInput3(e)}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div> */}
                    <StripeProvider
                      apiKey={
                        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
                        "pk_live_CeEh82TYTyUbPvi5CkQvLOSw00S4b8pC3R"
                      }
                    >
                      <Elements>
                        <CheckoutForm
                          load={this.state.loadPayScheduled}
                          handleSubmit={this.cardPaymentPlan.bind(this)}
                          validName={this.state.name}
                          validcard={this.state.card}
                          termMessage={this.state.terms_conditions}
                          error={this.state.err_message_plan}
                          paymentPlan={true}
                          btnclass={"btn-success mb-0"}
                          type={"plan"}
                          flow={
                            this.state.all_data &&
                            this.state.all_data.lead &&
                            this.state.all_data.lead.organisation
                              ? this.state.all_data.lead.organisation.flow
                              : null
                          }
                          organisation={
                            this.state.all_data && this.state.all_data.lead
                              ? this.state.all_data.lead.organisation
                              : null
                          }
                          selectedPlan={this.state.payment_plan}
                          firstPayment={
                            this.state.first_payment
                              ? this.changeDate(this.state.first_payment)
                              : null
                          }
                        />
                      </Elements>
                    </StripeProvider>
                  </form>
                  <div class="m-2"></div>

                  {/*{(this.state.err_message_plan !== "") &&*/}
                  {/*<p className="error_message">{this.state.err_message_plan}</p>}*/}
                  <div className="invoice-buttons text-center">
                    <button
                      onClick={this.handleClose.bind(this)}
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade modal_add_item" id="modal-editpayplan">
            <div
              className="modal-dialog modal-dialog-centered modal-pay_width paymentplan"
              id="modal-pay"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <h5 className="modal-title">Edit Payment Plan</h5>
                  <hr />
                  {this.state.all_data &&
                    this.state.all_data.lead &&
                    this.state.all_data.lead.organisation && (
                      <>
                        <p style={{ textAlign: "center" }}>
                          {this.amountToFixed(this.state.all_data.balance)}{" "}
                          scheduled payment to{" "}
                          {this.state.all_data.lead.organisation.business_name}.
                        </p>
                      </>
                    )}
                  <div class="m-2"></div>

                  {/* {!this.state.auth_token && (
                    <>
                      <div>
                        <p>
                          Existing Cloud Collect user?{" "}
                          <a
                            href="#modal-login"
                            data-toggle="modal"
                            data-target="#modal-login"
                          >
                            {" "}
                            Login
                          </a>
                          .
                        </p>
                      </div>
                      <hr />
                    </>
                  )} */}
                  <form>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label for="">Select Payment Plan</label>
                        <select
                          onChange={this.handleInputChange}
                          name="payment_plan"
                          className={
                            "form-control " +
                            (this.state.payment_plan_err ? "border_red" : "")
                          }
                          id="select"
                          value={this.state.payment_plan}
                        >
                          <option></option>
                          {paymentsPlan.map((plan) => (
                            <option value={plan.value}>{plan.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {this.state.payment_plan !== "" && (
                      <div
                        className="form-group row"
                        style={{ maxHeight: "69px" }}
                      >
                        <div className="col-sm-12">
                          <label for="">First Payment</label>
                          <input
                            className="form-control"
                            type="text"
                            aria-label="Search"
                          />
                          {paymentDates.length > 1 && (
                            <DatePicker
                              placeholderText="Date "
                              selected={this.state.first_payment}
                              minDate={new Date(paymentDates[0].value)}
                              maxDate={new Date(paymentDates[1].value)}
                              onChange={this.handleFirstPayment.bind(this)}
                              dateFormat={"dd/MM/yyyy"}
                            />
                          )}
                          {/* <select
                            onChange={this.handleInputChange}
                            name="first_payment"
                            className={
                              "form-control " +
                              (this.state.first_payment_err ? "border_red" : "")
                            }
                            id="select"
                            value={this.state.first_payment}
                          >
                            <option></option>
                            {paymentDates.map((plan) => (
                              <option value={plan.value}>{plan.label}</option>
                            ))}
                          </select> */}
                        </div>
                      </div>
                    )}

                    {this.state.payment_plan === "custom_payment_plan" && (
                      <>
                        {" "}
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label htmlFor="">Payment Amount</label>
                            <input
                              name="amount"
                              value={
                                this.getAmount() == 0.0
                                  ? "$XXX.XX"
                                  : this.getAmount()
                              }
                              type="number"
                              className="form-control"
                              aria-describedby=""
                              placeholder="$XXX.XX"
                              disabled
                            />
                          </div>
                          {flow.upfront_enabled === "1" && (
                            <div className="col-sm-6">
                              <label htmlFor="">Upfront Payment</label>
                              <input
                                type="number"
                                min="0"
                                step="0.01"
                                onChange={this.handleInputUpfront.bind(this)}
                                name="upfront"
                                onKeyPress={this.handleKeypress.bind(this)}
                                className={
                                  "form-control " +
                                  (this.state.upfront_err ||
                                  this.state.upfront_err_negative === true
                                    ? "border_red"
                                    : "")
                                }
                                placeholder=""
                              />
                            </div>
                          )}
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label htmlFor="">Frequency</label>
                            <select
                              onChange={this.handleFrequency.bind(this)}
                              name="frequency"
                              className={
                                "form-control " +
                                (this.state.frequency_err ? "border_red" : "")
                              }
                              id="select"
                              value={this.state.frequency}
                            >
                              <option></option>
                              {this.state.frequencyOptions.map(
                                (option, index) => {
                                  return (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                          <div className="col-sm-6">
                            <label htmlFor="">Number Of Payments</label>
                            <select
                              onChange={this.handleNoOfPayments.bind(this)}
                              value={this.state.no_of_payments}
                              name="no_of_payments"
                              className={
                                "form-control " +
                                (this.state.no_of_payments_err
                                  ? "border_red"
                                  : "")
                              }
                              id="select"
                            >
                              <option></option>
                              {this.state.noOfPaymentOptions.map(
                                (option, index) => {
                                  return (
                                    <option
                                      key={`payments${index}`}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                        </div>
                      </>
                    )}

                    <br />
                    {this.state.paymentSchedules &&
                      this.state.paymentSchedules.length > 0 && (
                        <>
                          <h6>Payment Schedule</h6>

                          {this.state.paymentSchedules.map((s, index) => (
                            <p style={{ fontSize: "14px" }}>
                              Payment #{index + 1} -{" "}
                              {this.amountToFixed(s.amount)} on {s.date}
                            </p>
                          ))}
                          <p>
                            Total Paid -{" "}
                            {this.amountToFixed(this.state.all_data.balance)}
                          </p>
                          <br />
                        </>
                      )}

                    <div className="row">
                      <div className="col-md-12 mb-4">
                        {/* <label htmlFor="">Upfront Payment</label> */}
                        <input
                          type="checkbox"
                          onChange={this.handleInputCheckbox.bind(this)}
                          name="changeCard"
                          placeholder=""
                          class="mr-2"
                          checked={this.state.changeCard}
                        />{" "}
                        Change Payment Method
                      </div>
                    </div>

                    {this.state.changeCard === 1 ? (
                      <StripeProvider
                        apiKey={
                          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
                          "pk_live_CeEh82TYTyUbPvi5CkQvLOSw00S4b8pC3R"
                        }
                      >
                        <Elements>
                          <CheckoutForm
                            load={this.state.loadPayScheduled}
                            handleSubmit={this.editCardPaymentPlan.bind(this)}
                            validName={this.state.name}
                            validcard={this.state.card}
                            termMessage={this.state.terms_conditions}
                            error={this.state.err_message_plan}
                            paymentPlan={true}
                            btnclass={"btn-success mb-0"}
                            type={"plan"}
                            flow={
                              this.state.all_data &&
                              this.state.all_data.lead &&
                              this.state.all_data.lead.organisation
                                ? this.state.all_data.lead.organisation.flow
                                : null
                            }
                            organisation={
                              this.state.all_data && this.state.all_data.lead
                                ? this.state.all_data.lead.organisation
                                : null
                            }
                            selectedPlan={this.state.payment_plan}
                            firstPayment={
                              this.state.first_payment
                                ? this.changeDate(this.state.first_payment)
                                : null
                            }
                          />
                        </Elements>
                      </StripeProvider>
                    ) : (
                      <>
                        {organisation &&
                          flow &&
                          selectedPlan &&
                          firstPayment &&
                          flow[selectedPlan] !== "Auto Accept" && (
                            <>
                              <p class="mb-2">
                                {organisation.business_name} has until{" "}
                                {firstPayment} to approve this payment plan
                                request.
                              </p>
                              <br />
                            </>
                          )}
                        {flow && (
                          <>
                            {flow.retry_payment === 1 &&
                            flow.retry_attempt > 0 &&
                            flow.retry_attempt_fee > 0 ? (
                              <p class="">
                                Please ensure you have sufficient funds, as a
                                fee of ${flow.retry_attempt_fee} will be charged
                                to retry payment.
                              </p>
                            ) : (
                              <p>
                                Please ensure you have sufficient funds on the
                                selected date
                              </p>
                            )}
                            <br />

                            <p>
                              By clicking 'Submit' below you agree to our{" "}
                              <a
                                target="blank"
                                href="https://cloudcollect.io/terms/"
                              >
                                terms and conditions
                              </a>
                              .
                            </p>
                            <br />

                            <div className="invoice-buttons text-center">
                              <button
                                onClick={() => this.editPaymentPlan()}
                                type="button"
                                className="btn btn-success mb-0"
                              >
                                Submit
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </form>
                  <div class="m-2"></div>

                  {/*{(this.state.err_message_plan !== "") &&*/}
                  {/*<p className="error_message">{this.state.err_message_plan}</p>}*/}
                  <div className="invoice-buttons text-center">
                    <button
                      onClick={this.handleClose.bind(this)}
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade modal_add_item" id="modal-cancelplan">
            <div
              className="modal-dialog modal-dialog-centered modal-pay_width"
              id="modal-pay1"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <div class="modal-title h4">Delete</div>
                  <button type="button" class="close">
                    <span aria-hidden="true">×</span>
                    <span class="sr-only">Close</span>
                  </button>
                </div>
                <div class="modal-body">
                  Are you sure you want to cancel this plan?
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={this.cancelPaymentPlan}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal modal_add_item fade" id="modal-login">
            <div className="modal-dialog modal-dialog" role="document">
              <div
                className="modal-dialog modal-dialog-centered modal-pay_width"
                id="modal-pay"
              >
                <div className="modal-content  login-modal">
                  <div className="modal-header">
                    <p className="onboard-heading">Login to Cloud Collect</p>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      <span>&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <LoginForm onSubmit={this.handleLoginSubmit} />
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Terms and Conditions Modal --> */}
          <div className="modal modal_add_item fade" id="modal-tcs">
            <div className="modal-dialog modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <p className="onboard-heading">Terms & Conditions</p>
                  <button type="button" className="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p></p>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const { fetchNotification } = notificationAction;

const mapDispatchToProps = (dispatch) => ({
  fetchNotification: (payload, history) =>
    fetchNotification({ dispatch, payload, history }),
  userInfo: getUser(),
});

export default connect(mapDispatchToProps)(PreviewInvoice);
