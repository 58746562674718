import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import alertActions from "../alert/actions";
import { toast } from "react-toastify";
import {
  setToken,
  setUser,
  clearToken,
  clearUser,
  getToken,
  getUser,
} from "../../helpers/utility";
import AuthHelper from "../../helpers/authHelper";
import { createNotification } from "../../components/uielements/notification";

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* ({ payload }) {
    try {
      const { history, userInfo } = payload;
      const result = yield call(AuthHelper.login, userInfo);

      if (result.token) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          payload: result,
          history,
        });
      }
    } catch (error) {
      yield put({
        type: alertActions.ADD_ERROR,
        payload: error,
      });
    }
  });
}

export function* fetchCurrentUser() {
  yield takeEvery("FETCH_CURRENT_USER", function* () {
    try {
      const result = yield call(AuthHelper.fetchCurrentUser);
      yield setUser(result);
      yield put({
        type: actions.FETCH_CURRENT_USER_SUCCESS,
        payload: result,
      });
    } catch (error) {}
  });
}

export function* registerRequest() {
  yield takeEvery("REGISTER_REQUEST", function* ({ payload }) {
    try {
      const { history, userInfo } = payload;
      const result = yield call(AuthHelper.register, userInfo);
      if (result.token) {
        yield put({
          type: actions.REGISTER_SUCCESS,
          payload: result,
          history,
        });
      }
    } catch (error) {
      yield put({
        type: alertActions.ADD_ERROR,
        payload: error,
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* ({ payload, history }) {
    try {
      yield setUser(payload.user);
      yield setToken(payload.token);
      if (history) {
        window.location.href = "/invoices";
      }
    } catch (error) {
      yield put({
        type: alertActions.ADD_ERROR,
        payload: error.error || error.message,
      });
    }
  });
}

export function* forgotRequest() {
  yield takeEvery("FORGOT_PASSWORD_REQUEST", function* ({ payload }) {
    try {
      const { history, userInfo, donotRedirect} = payload;
      const result = yield call(AuthHelper.forgotPassword, userInfo);
      createNotification(
        "success",
        "Password reset link has been sent to your email"
      );
      if (!donotRedirect){
        history.push("/login");
      }
    } catch (error) {
      createNotification("danger", error.error || error.message);
    }
  });
}
export function* resetPasswordRequest() {
  yield takeEvery("RESET_PASSWORD_REQUEST", function* ({ payload }) {
    try {
      const { history, userInfo } = payload;
      const result = yield call(AuthHelper.resetPassword, userInfo);
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: result,
        history,
      });
      createNotification("success", "Password reset successfully");
      history.push("/");
    } catch (error) {
      createNotification(
        "danger",
        error.email || error.password || error.error || "Reset Password Error"
      );
    }
  });
}

export function* registerSuccess() {
  yield takeEvery(actions.REGISTER_SUCCESS, function* ({ payload, history }) {
    yield setToken(payload.token);
    yield setUser(payload.user);
    if (history) {
      window.location.href = "/organisation";
    }
  });
}

export function* refreshToken() {
  yield takeEvery(actions.REFRESH_TOKEN, function* () {
    const result = yield call(AuthHelper.refreshToken);
    if (result) {
      yield setToken(result.access_token);
      yield put({
        type: actions.REFRESH_TOKEN_SUCCESS,
        payload: result,
      });
    } else {
    }
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearToken();
    clearUser();
    yield put(push("/"));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* (history) {
    const { token } = AuthHelper.checkExpirity(getToken());
    const { user } = getUser();
    if (token && user) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: { token, user },
        token,
        user,
        profile: "Profile",
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(registerRequest),
    fork(registerSuccess),
    fork(checkAuthorization),
    fork(loginRequest),
    fork(logout),
    fork(refreshToken),
    fork(loginSuccess),
    fork(forgotRequest),
    fork(resetPasswordRequest),
    fork(fetchCurrentUser),
  ]);
}
