import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { plans } from "../../../_methods/commonMethods";

const renderField = (props) => {
  const { subscriptionRequested } = props;
  console.log("subscriptionRequested", subscriptionRequested);
  return (
    <>
      {props.plans.map((plan) => (
        <div key={plan.value}>
          <div className="form-check">
            <input
              className="form-check-input"
              type={"radio"}
              {...props.input}
              value={plan.value}
              id={plan.label}
              checked={
                props.input.value === plan.value ||
                (props.input.value == "Trial" &&
                  plan.value == "Standard" &&
                  subscriptionRequested &&
                  subscriptionRequested.plan_id == 2) ||
                (props.input.value == "Trial" &&
                  plan.value == "Freemium" &&
                  subscriptionRequested &&
                  subscriptionRequested.plan_id == 1)
              }
            />
            <label htmlFor={plan.label}>{plan.label}</label>
          </div>
        </div>
      ))}
      {props.meta.touched &&
        ((props.meta.error && (
          <span className="error">{props.meta.error}</span>
        )) ||
          (props.meta.warning && (
            <span className="warning">{props.meta.warning}</span>
          )))}
      <br />
    </>
  );
};

const validate = (values, props) => {
  const errors = {};

  if (!values.plan) {
    errors.plan = "Please select a plan.";
  }

  return errors;
};

class SubscriptionSection extends Component {
  render() {
    const {
      handleSubmit,
      loading,
      subscription,
      subscriptionRequested,
    } = this.props;
    return (
      <div className="col-lg-4 mt-3">
        <div className="card">
          <div className="card-body">
            <p className="onboard-heading">Subscription</p>
            <hr />
            {console.log("subscription", subscription)}
            {subscription &&
              subscription.plan_id == 3 &&
              subscription.trial_ends_in > 0 && (
                <p
                  style={{
                    fontWeight: "strong",
                    color: "red",
                    textAlign: "center",
                  }}
                >
                  Trial ends in {subscription.trial_ends_in}{" "}
                  {subscription.trial_ends_in > 1 ? "Days" : "Day"}.
                </p>
              )}
            <div className="col-md-12 mt-3">
              <form onSubmit={handleSubmit}>
                <fieldset className="form-group">
                  <Field
                    name="plan"
                    component={renderField}
                    plans={plans.filter((plan) => plan.value !== "Trial")}
                    subscriptionRequested={subscriptionRequested}
                  />
                </fieldset>

                <p>
                  <small>
                    *Payment gateway and SMS will incur pay per use fees.
                  </small>
                </p>
                <hr />
                <div className="invoice-buttons text-center">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={loading}
                  >
                    {loading && <i class="fa fa-spinner fa-spin" />} Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "SubscriptionForm",
  validate,
  enableReinitialize: true,
})(SubscriptionSection);
