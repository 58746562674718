import React, { Component } from "react";
import { connect } from "react-redux";
import SuperFetch from "../../../helpers/superFetch";
import { getToken } from "../../../helpers/utility";
import url from "../../../url";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { apiSocketHost } from "../../../config";

class PaymentReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      to_date: "",
      from_date: "",
      payment_type: "All",
      keyword: "",
      payments: [],
      downloading: false,
    };
    this.handleFromDate = this.handleFromDate.bind(this);
    this.handleToDate = this.handleToDate.bind(this);
  }

  componentDidMount() {
    document.title = "Cloud Collect - Payments";
    this.getPaymentData();
  }

  getDate(data) {
    let newDate = new Date(data);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return year + "-" + this.addZero(month) + "-" + this.addZero(date);
  }

  changeDate(data) {
    let newDate = new Date(data);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return this.addZero(date) + "/" + this.addZero(month) + "/" + year;
  }

  getChangeDate(data) {
    if (data) {
      let date = data.split("-");
      let day = date[2].split(" ");
      return day[0] + "/" + date[1] + "/" + date[0];
    }
    return;
  }

  addZero(day) {
    day = parseInt(day).toString();
    if (day.length === 1) day = "0" + day;
    return day;
  }

  handleFromDate(e) {
    var date = null;
    if (e) {
      date = e;
    }
    this.setState({ from_date: date }, () => {
      // this.handleFilterDate();
      this.getPaymentData();
    });
  }

  handleToDate(e) {
    var date = null;
    if (e) {
      date = e;
    }
    this.setState({ to_date: date }, () => {
      // this.handleFilterDate();
      this.getPaymentData();
    });
  }

  getPaymentData() {
    let url = "/reports/payment-detailed?";

    if (this.state.from_date && this.state.from_date != "") {
      url += `&start_date=${this.getDate(this.state.from_date)}`;
    }
    if (this.state.to_date && this.state.to_date != "") {
      url += `&end_date=${this.getDate(this.state.to_date)}`;
    }
    if (this.state.payment_type && this.state.payment_type != "") {
      url += `&payment_type=${this.state.payment_type}`;
    }
    if (this.state.keyword && this.state.keyword != "") {
      url += `&keyword=${this.state.keyword}`;
    }
    SuperFetch.get(url).then((response) => {
      if (response && response.data) this.setState({ payments: response.data });
    });
  }

  handleTypeChange = (e) => {
    let value = e.target.value;
    this.setState(
      {
        payment_type: value,
      },
      () => {
        this.getPaymentData();
      }
    );
  };

  handleKeywordChange = (e) => {
    let value = e.target.value;
    this.setState(
      {
        keyword: value,
      },
      () => {
        this.getPaymentData();
      }
    );
  };

  downloadReport = () => {
    this.setState({
      downloading: true,
    });
    let url = "/reports/payment-detailed?download=true";

    if (this.state.from_date && this.state.from_date != "") {
      url += `&start_date=${this.getDate(this.state.from_date)}`;
    }
    if (this.state.to_date && this.state.to_date != "") {
      url += `&end_date=${this.getDate(this.state.to_date)}`;
    }
    if (this.state.payment_type && this.state.payment_type != "") {
      url += `&payment_type=${this.state.payment_type}`;
    }
    if (this.state.keyword && this.state.keyword != "") {
      url += `&keyword=${this.state.keyword}`;
    }
    SuperFetch.get(url)
      .then((response) => {
        this.setState({
          downloading: false,
        });
        if (response && response.data && response.data.url) {
          window.open(
            apiSocketHost.fetchUrl +
              `/download-csv/?url=${response.data.url}&name=payment-report.csv`,
            "_blank",
            "noopener,noreferrer"
          );
        }
      })
      .catch(() => {
        this.setState({
          downloading: false,
        });
      });
  };

  render() {
    const columns = [
      {
        name: "Date",
        selector: "created_at",
        sortable: true,
        cell: (transaction) => {
          return this.getDate(transaction.created_at);
        },
      },
      {
        name: "Name",
        selector: "invoice.lead.full_name",
        sortable: true,
        cell: (transaction) => {
          return transaction.invoice.lead.full_name;
        },
      },
      {
        name: "Reference",
        selector: "invoice.invoice_number",
        sortable: true,
        cell: (transaction) => {
          return transaction.invoice.invoice_number;
        },
      },
      {
        name: "Type",
        selector: "type",
        sortable: true,
        cell: (transaction) => {
          return transaction.type;
        },
      },
      {
        name: "Payment",
        selector: "amount",
        sortable: true,
        cell: (transaction) => {
          return `$${transaction.amount}`;
        },
      },
      {
        name: "Payment GST",
        selector: "payment_gst",
        sortable: true,
        cell: (transaction) => {
          return `$${transaction.payment_gst}`;
        },
      },
      {
        name: "Fee",
        selector: "stripe_fees",
        sortable: true,
        cell: (transaction) => {
          return `$${transaction.stripe_fees}`;
        },
      },
      {
        name: "Fee GST",
        selector: "tax",
        sortable: true,
        cell: (transaction) => {
          return `$${transaction.tax}`;
        },
      },
      {
        name: "Net Payment",
        selector: "net_payment",
        sortable: true,
        cell: (transaction) => {
          return `$${transaction.net_payment}`;
        },
      },
    ];

    const customStyles = {
      headCells: {
        style: {
          fontSize: "14px",
          fontWeight: "600",
        },
      },
    };
    return (
      <div className="main-content">
        <div className="main-content-inner">
          <div className="row">
            <div className="col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div class="col-md-9 col-sm-12 c-buttons">
                      <div className="input-group">
                        <button
                          id="button-float-left"
                          style={{ marginRight: 15 }}
                          type="button"
                          className="btn btn-secondary"
                          onClick={this.downloadReport}
                        >
                          Export
                        </button>
                        <div className="">
                          <Link to={url.reports.payment.paymentSummary}>
                            <button
                              className="btn btn-secondary "
                              type="button"
                              id="button-float-left"
                            >
                              Summary Report
                            </button>
                          </Link>
                        </div>
                      </div>
                      {/* <div class="input-group">
                        <button
                          className="btn btn-secondary mt-1"
                          onClick={this.downloadReport}
                        >
                          Export
                        </button>
                        <Link to={url.reports.payment.paymentSummary}>
                          <button
                            className="btn btn-secondary mt-1"
                            onClick={this.downloadReport}
                          >
                            Summary Report
                          </button>
                        </Link>
                      </div> */}
                    </div>
                  </div>

                  <div className="row list-fields">
                    <div className="col-12 col-md-3">
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div class="input-group-text" id="input-label-large">
                            Search:
                          </div>
                        </div>
                        <input
                          name="Search"
                          class="form-control"
                          placeholder="Name, Reference or Amount"
                          onChange={this.handleKeywordChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div class="input-group mb-2">
                        <div
                          class="input-group-prepend"
                          style={{ height: "44px" }}
                        >
                          <div class="input-group-text" id="input-label-large">
                            Type:
                          </div>
                        </div>
                        <select
                          name="type"
                          class="form-control"
                          onChange={this.handleTypeChange}
                          style={{ height: "44px" }}
                        >
                          <option value="All">All</option>
                          <option value="Succeeded">Successful</option>
                          <option value="Now">Pay Now</option>
                          <option value="Later">Pay Later</option>
                          <option value="Plan">Payment Plan</option>
                          <option value="Failed">Failed Payment</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-12 col-md-3">
                      <div class="input-group mb-2">
                        <div
                          class="input-group-prepend"
                          style={{ height: "44px" }}
                        >
                          <div class="input-group-text" id="input-label-large">
                            Date From:
                          </div>
                        </div>
                        <DatePicker
                          className="form-control date-picker-input"
                          selected={this.state.from_date}
                          onChange={this.handleFromDate.bind(this)}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <label class="sr-only" for="inlineFormInputGroup">
                        Date To
                      </label>
                      <div class="input-group mb-2">
                        <div
                          class="input-group-prepend"
                          style={{ height: "44px" }}
                        >
                          <div class="input-group-text" id="input-label-large">
                            Date To:
                          </div>
                        </div>
                        <DatePicker
                          className="form-control date-picker-input"
                          selected={this.state.to_date}
                          onChange={this.handleToDate.bind(this)}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div
                    className="single-table single-table_1"
                    id="table-contacts"
                  >
                    <div className="">
                      <DataTable
                        noHeader
                        overflowY
                        highlightOnHover
                        responsive={true}
                        title=""
                        columns={columns}
                        data={this.state.payments || []}
                        defaultSortField="Name"
                        customStyles={customStyles}
                        pointerOnHover={true}
                        pagination={true}
                        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const { login } = authAction
const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {
    userToken: getToken(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReport);
