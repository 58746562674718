import React, { Component } from "react";
import { Link } from "react-router-dom";
import url from "../../../url";
import "./settings.scss";
import businessActions from "../../../redux/businessUser/actions";
import { connect } from "react-redux";

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_id: null,
      entity_status: null,
    };
  }

  componentDidMount() {
    this.props.fetchMyBusinessInvites();
  }

  handleEntity = (id, status) => {
    this.setState({
      entity_id: id,
      entity_status: status,
    });
  };

  updateInvite = () => {
    const { history } = this.props;

    this.props.acceptRejectBusinessInvite(history, {
      id: this.state.entity_id,
      status: this.state.entity_status,
    });
  };

  render() {
    return (
      <div className="main-content-inner">
        {this.props.myBusinessInvites.length != 0 && (
          <div className="row">
            <div className="col-lg-8 mt-3">
              <div className="card">
                <div className="card-body">
                  {this.props.myBusinessInvites.map((invite, index) => (
                    <>
                      <div className="row" key={index}>
                        <div className="col-md-6">
                          {index + 1}. You have been invited to join{" "}
                          {invite.organisation.business_name.toUpperCase()}
                        </div>

                        <div className="col-md-6">
                          <div className="btn-group">
                            <button
                              className="btn btn-outline-primary btn-xs mb-0"
                              onClick={() =>
                                this.handleEntity(invite.id, "Accepted")
                              }
                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              Accept
                            </button>
                            <button
                              className="btn btn-outline-danger btn-xs mb-0"
                              onClick={() =>
                                this.handleEntity(invite.id, "Declined")
                              }
                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              Decline
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  <div className="modal fade" id="myModal">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Are you sure?</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-sm btn-danger"
                            onClick={() => this.updateInvite()}
                            data-dismiss="modal"
                            data-target="#myModal"
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            data-dismiss="modal"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-8 mt-3">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <Link to="/organisation">
                      <div className="card" id="card-inner-1">
                        <div className="card-body">
                          <h5 className="card-title">Organisation Details</h5>
                          <p className="card-text">
                            Contact information and entity details.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-6">
                    <Link to="/users">
                      <div className="card" id="card-inner-1">
                        <div className="card-body">
                          <h5 className="card-title">Users</h5>
                          <p className="card-text">Add or remove users.</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <Link to={url.utilities.notifications}>
                      <div className="card" id="card-inner-1">
                        <div className="card-body">
                          <h5 className="card-title">Notifications</h5>
                          <p className="card-text">
                            Manage notifications that you receive.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-6">
                    <Link to="/templates">
                      <div className="card" id="card-inner-1">
                        <div className="card-body">
                          <h5 className="card-title">Communications</h5>
                          <p className="card-text">
                            Manage emails and SMS that are sent to contacts.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <Link to="/flow">
                      <div className="card" id="card-inner-1">
                        <div className="card-body">
                          <h5 className="card-title">Debtor Control</h5>
                          <p className="card-text">
                            Manage the settings that automate debtor control.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-6">
                    <Link to={url.utilities.finance}>
                      <div className="card" id="card-inner-1">
                        <div className="card-body">
                          <h5 className="card-title">Finance</h5>
                          <p className="card-text">
                            Manage payment gateway, bank accounts and
                            subscription.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const {
  fetchMyBusinessInvites,
  acceptRejectBusinessInvite,
  fetchBusinessOrganisations,
} = businessActions;

const mapDispatchToProps = (dispatch) => ({
  fetchMyBusinessInvites: () => fetchMyBusinessInvites({ dispatch }),
  fetchBusinessOrganisations: () => fetchBusinessOrganisations({ dispatch }),
  acceptRejectBusinessInvite: (history, payload) =>
    acceptRejectBusinessInvite({ dispatch, history, payload }),
});

const mapStateToProps = (state) => {
  return {
    loading: state.BusinessUser.loading,
    myBusinessInvites: state.BusinessUser.myBusinessInvites,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
