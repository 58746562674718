import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

const FileInput = ({
  meta: { omitMeta, touched, error, warning },
  label,
  type,
  readOnly,
  disabled,
  id,
  accept,
  ...props
}) => {
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    if (props.input.value) {
      let reader = new FileReader();
      let file = props.input.value;

      reader.onloadend = () => {
        if (props.input.value) {
          setPreviewUrl(reader.result);
        } else {
          setPreviewUrl(null);
        }
      };

      reader.readAsDataURL(file);
    } else {
      setPreviewUrl(null);
    }
  }, [props.input.value]);

  const onChange = (e) => {
    const file = e.target.files && e.target.files[0];
    props.input.onChange(file);
  };

  const handleClick = () => {
    setPreviewUrl(null);
    props.input.onChange(null);
  };
  return (
    <div>
      <label class="sr-only" for="inlineFormInputGroup"></label>
      <div class="input-group mb-2">
        <div class="input-group-prepend">
          <div class="input-group-text" id="input-label-large">
            {label}
          </div>
        </div>
        <div class="custom-file">
          <input
            type="file"
            accept=".jpg, .png, .jpeg"
            onChange={(e) => onChange(e)}
            disabled={readOnly}
            class="custom-file-input"
            {...props}
          />
          <label class="custom-file-label" for="customFile">
            Attach
          </label>
        </div>
      </div>
      {previewUrl && props.input.value ? (
        <>
          <img
            className="upload-field-preview"
            src={previewUrl}
            width="200"
            height="200"
          />
          {readOnly ? (
            <span className="close" style={{ position: "absolute" }}>
              {" "}
              <span>X</span>
            </span>
          ) : (
            <span
              className="close"
              style={{ position: "absolute" }}
              onClick={handleClick}
            >
              {" "}
              <span>X</span>
            </span>
          )}
        </>
      ) : null}
      {touched &&
        ((error && <span className="error">This Field {error}</span>) ||
          (warning && <span className="warning">{warning}</span>))}
    </div>
  );
};

const renderField = ({
  input,
  label,
  type,
  readOnly,
  disabled,
  id,
  meta: { touched, error, warning },
}) => (
  <>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text" id="input-label-large">
          {label}
        </div>
      </div>
      <input
        {...input}
        type={type}
        className="form-control"
        id="inlineFormInputGroup"
        placeholder=""
        readOnly={readOnly}
        id={id}
      />
    </div>
    {touched &&
      ((error && <span className="error">This Field {error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </>
);

const renderDate = ({
  input,
  label,
  type,
  readOnly,
  disabled,
  id,
  meta: { touched, error, warning },

  
}) => (
  

  <>
    <div class="input-group mb-2 custom-datepicker">
      <div class="input-group-prepend">
        <div class="input-group-text" id="input-label-large">
          {label}
        </div>
      </div>

      <DatePicker
        {...input}
        className="form-control"
        dateFormat={"dd/MM/yyyy"}
        selected={input.value && input.value != "" ? new Date(moment(input.value, 'DD-MM-YYYY').format('YYYY-MM-DD')) : null}
        onChange={(date) => {
          if (date && date != "" && date.length === 10){
            input.onChange(moment(date).format("DD/MM/YYYY"));
          } else {
            input.onChange(date);
          }
        }}
      />
    </div>
    {touched &&
      ((error && <span className="error">This Field {error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </>
);

const renderSelect = ({
  input,
  label,
  type,
  readOnly,
  id,
  options,
  meta: { touched, error, warning },
}) => (
  <>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text" id="input-label-large">
          {label}
        </div>
      </div>
      <select
        {...input}
        className="form-control"
        style={{ height: "50px" }}
        disabled={readOnly}
        // id="inlineFormInputGroup"
      >
        <option value=""></option>
        {options &&
          options.map((option, index) => (
            <option key={id + index} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      {touched &&
        ((error && <span className="error">This Field {error}</span>) ||
          (warning && <span className="warning">{warning}</span>))}
    </div>
  </>
);

const renderTextArea = ({
  label,
  rows,
  className,
  input,
  meta: { touched, error },
}) => (
  <div>
    <div>
      <label htmlFor="description">{label}</label>
      <textarea
        {...input}
        className={className}
        rows={rows}
        cols="40"
        id="description"
        className="form-control"
      />
      {touched && error && (
        <div
          className="form__field-error"
          style={{ color: "red" }}
        >{`This field is ${error}`}</div>
      )}
    </div>
  </div>
);

const renderCheckBox = (props) => {
  const { meta = {} } = props;

  const checboxProps = {
    type: "checkbox",
    className: "form-check-input",
    name: props.input.name,
    id: props.input.name,
    value: props.input.value ? props.input.value : props.input.name,
    defaultChecked: meta.uncontrolled ? props.defaultChecked : undefined,
    onChange: props.input.onChange,
    checked: props.input.value,
  };

  return (
    <div className="form-group">
      <input {...checboxProps} {...props} />
      <label
        className="form-check-label"
        htmlFor={props.htmlFor || props.input.name}
      >
        {props.label}
      </label>
      {meta.touched && meta.error ? (
        <div className="form__field-error" style={{ color: "red" }}>
          {props.requiredError || `Please accept our terms and conditions.`}
        </div>
      ) : null}
    </div>
  );
};

export {
  renderField,
  renderSelect,
  FileInput,
  renderTextArea,
  renderCheckBox,
  renderDate,
};
