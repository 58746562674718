const actions = {
  FETCH_ORGANISATION: "FETCH_ORGANISATION",
  FETCH_SINGLE_ORGANISATION: "FETCH_SINGLE_ORGANISATION",
  FETCH_ORGANISATION_SUCCESS: "FETCH_ORGANISATION_SUCCESS",
  UPDATE_DIRECTOR: "UPDATE_DIRECTOR",
  UPDATE_ORGANISATION: "UPDATE_ORGANISATION",
  UPDATE_DIRECTOR_SUCCESS: "UPDATE_DIRECTOR_SUCCESS",
  UPDATE_ORGANISATION_SUCCESS: "UPDATE_ORGANISATION_SUCCESS",
  REMOVE_LOADER: "REMOVE_LOADER",
  ORGANISATION_LOADING: "ORGANISATION_LOADING",
  UPDATE_DEPOSIT: "UPDATE_DEPOSIT",
  UPDATE_BILLING: "UPDATE_BILLING",
  UPDATE_CREDIT_CARD: "UPDATE_CREDIT_CARD",
  CREATE_CREDIT_CARD: "CREATE_CREDIT_CARD",
  DELETE_CREDIT_CARD: "DELETE_CREDIT_CARD",
  fetchOrganisation: (payload) => ({
    type: actions.FETCH_ORGANISATION,
    payload,
  }),
  fetchSingleOrganisation: (payload) => ({
    type: actions.FETCH_SINGLE_ORGANISATION,
    payload,
  }),
  updateOrganisation: (payload) => ({
    type: actions.UPDATE_ORGANISATION,
    payload,
  }),
  updateDeposit: (payload) => ({
    type: actions.UPDATE_DEPOSIT,
    payload,
  }),
  updateBilling: (payload) => ({
    type: actions.UPDATE_BILLING,
    payload,
  }),
  updateDirector: (payload) => ({
    type: actions.UPDATE_DIRECTOR,
    payload,
  }),
  updateCreditCard: (payload) => ({
    type: actions.UPDATE_CREDIT_CARD,
    payload,
  }),
  createCreditCard: (payload) => ({
    type: actions.CREATE_CREDIT_CARD,
    payload,
  }),
  deleteCreditCard: (payload) => ({
    type: actions.DELETE_CREDIT_CARD,
    payload,
  }),
};
export default actions;
