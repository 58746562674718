import React, { Component } from "react";
import { Link } from "react-router-dom";
import url from "../../url";
import notificationAction from "../../redux/notification/actions";
import { connect } from "react-redux";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "",
    };
  }

  componentDidMount() {
    let url = this.props.history.location.pathname;
    this.setState({ page: url });
    // this.props.fetchNotification();
  }

  componentDidUpdate() {
    let url = this.props.history.location.pathname;
    if (url !== this.state.page) this.setState({ page: url });
  }

  handleLogout = () => {
    localStorage.removeItem("cloud_collect_userInfo");
    localStorage.removeItem("id_token");
    window.location.href = url.index;
  };

  render() {
    return (
      <>
        {this.props.userInfo &&
        this.props.userInfo.role &&
        this.props.userInfo.role[0].title === "Business" ? (
          <div className="sidebar-menu">
            <div className="sidebar-header">
              <div className="logo">
                <h2>Cloud Collect</h2>
              </div>
              <div className="business_name" style={{ color: "#f7f7f7" }}>
                <h5>
                  {this.props.userInfo &&
                    this.props.userInfo.organisation &&
                    this.props.userInfo.organisation.business_name}
                </h5>
              </div>
            </div>

            <div>
              {this.props.userInfo &&
                this.props.userInfo.primary_organisation &&
                this.props.userInfo.primary_organisation.subscription &&
                this.props.userInfo.primary_organisation.subscription.plan_id ==
                  3 && (
                  <div class="trial-summary">
                    <Link to={url.utilities.finance}>
                      Trial Ends in{" "}
                      {
                        this.props.userInfo.primary_organisation.subscription
                          .trial_ends_in
                      }{" "}
                      {this.props.userInfo.primary_organisation.subscription
                        .trial_ends_in > 1
                        ? "Days"
                        : "Day"}
                    </Link>
                  </div>
                )}
            </div>
            <div>
              <div className="row">
                <div id="tool-tray">
                  <ul className="notification-area pull-right">
                    <li id="full-view">
                      <i className="ti-fullscreen"></i>
                    </li>
                    <li id="full-view-exit">
                      <i className="ti-zoom-out"></i>
                    </li>
                    <li className="customer-new-btn">
                      <i className="fa fa-plus"></i>
                    </li>
                    <li className="settings-btn">
                      <i className="fa fa-bell-o">
                        {this.props.allNotifications &&
                          this.props.allNotifications.allNotifications.unread &&
                          this.props.allNotifications.allNotifications.unread
                            .length !== 0 && <span>!</span>}
                      </i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="main-menu"
              style={{ margin: "-20px 20px 0px 20px", width: "90%" }}
            >
              <div
                className="slimScrollDiv"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  width: "auto",
                  height: "693.587px",
                }}
              >
                <div
                  className="menu-inner"
                  style={{ width: "auto", height: "693.587px" }}
                >
                  <nav>
                    <ul className="metismenu" id="menu">
                      {/* <li
                        className={
                          this.state.page === url.dashboard.index
                            ? "active"
                            : null
                        }
                      >
                        <Link to={url.dashboard.index}>
                          <i className="ti-dashboard"></i>
                          <span>Dashboard</span>
                        </Link>
                      </li> */}

                      <li
                        className={
                          this.state.page === url.utilities.invoice.list
                            ? "active"
                            : null
                        }
                      >
                        <Link
                          to={url.utilities.invoice.list}
                          className="menuItem"
                        >
                          <i className="fas fa-file-invoice-dollar"></i>
                          <span>Invoices</span>
                        </Link>
                      </li>

                      <li
                        className={
                          this.state.page === url.utilities.proposal.index
                            ? "active"
                            : null
                        }
                      >
                        <Link
                          to={url.utilities.proposal.index}
                          className="menuItem"
                        >
                          <i className="fas fa-file-invoice"></i>
                          <span>Proposals</span>
                        </Link>
                      </li>

                      <li
                        className={
                          this.state.page === url.utilities.contact
                            ? "active"
                            : null
                        }
                      >
                        <Link to={url.utilities.contact} className="menuItem">
                          <i className="fa fa-users"></i>
                          <span>Contacts</span>
                        </Link>
                      </li>

                      <li
                        className={
                          this.state.page === url.utilities.products.index
                            ? "active"
                            : null
                        }
                      >
                        <Link
                          to={url.utilities.products.index}
                          className="menuItem"
                        >
                          <i className="fa fa-archive"></i>
                          <span>Products</span>
                        </Link>
                      </li>

                      <li
                        className={
                          this.state.page === url.reports.payment.payment ? "active" : null
                        }
                        id="hide-content"
                      >
                        <Link to={url.reports.payment.payment} className="menuItem">
                          <i className="ti-credit-card"></i>
                          <span>Payments</span>
                        </Link>
                      </li>

                      <li
                        className={
                          this.state.page === url.reports.home ? "active" : null
                        }
                        id="hide-content"
                      >
                        <Link to={url.reports.home} className="menuItem">
                          <i className="ti-pie-chart"></i>
                          <span>Reports</span>
                        </Link>
                      </li>

                      <li
                        className={
                          this.state.page === url.utilities.settings
                            ? "active"
                            : null
                        }
                      >
                        <Link to={url.utilities.settings} className="menuItem">
                          <i className="fas fa-cog"></i>
                          <span>Settings</span>
                        </Link>
                      </li>
                      <li
                      >
                        <a
                          target="_blank"
                          href="https://cloudcollect.zendesk.com/hc/en-us"
                          className="menuItem"
                        >
                          <i className="fas fa-question-circle"></i>
                          <span>Support</span>
                        </a>
                      </li>

                      {/* <li
                        id="hide-content"
                        className={
                          this.state.page === url.reports.debtor.debtor ||
                          this.state.page === url.reports.earnings ||
                          this.state.page === url.reports.performance ||
                          this.state.page ===
                            url.reports.debtor.debtorSummary ||
                          this.state.page === url.reports.income.income ||
                          this.state.page ===
                            url.reports.income.incomeSummary ||
                          this.state.page === url.reports.payment.payment
                            ? "active"
                            : null
                        }
                      >
                        <Link
                          aria-expanded="true"
                          to={""}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <i className="ti-pie-chart"></i>
                          <span>Reports</span>
                        </Link>
                        <ul className="collapse">
                          <li
                            className={
                              this.state.page ===
                              url.reports.debtor.debtorSummary
                                ? "active"
                                : null
                            }
                          >
                            <Link to={url.reports.debtor.debtorSummary}>
                              Debtor Summary
                            </Link>
                          </li>
                          <li
                            className={
                              this.state.page === url.reports.debtor.debtor
                                ? "active"
                                : null
                            }
                          >
                            <Link to={url.reports.debtor.debtor}>
                              Debtor Detail
                            </Link>
                          </li> */}

                      {/* <li
                            className={
                              this.state.page ===
                              url.reports.income.incomeSummary
                                ? "active"
                                : null
                            }
                          >
                            <Link to={url.reports.income.incomeSummary}>
                              Income Summary
                            </Link>
                          </li> */}
                      {/* <li
                            className={
                              this.state.page === url.reports.income.income
                                ? "active"
                                : null
                            }
                          >
                            <Link to={url.reports.income.income}>
                              Income Detail
                            </Link>
                          </li> */}

                      {/* <li
                            className={
                              this.state.page ===
                              url.reports.payment.paymentSummary
                                ? "active"
                                : null
                            }
                          >
                            <Link to={url.reports.payment.paymentSummary}>
                              Payment Summary
                            </Link>
                          </li> */}
                      {/* <li
                            className={
                              this.state.page === url.reports.payment.payment
                                ? "active"
                                : null
                            }
                          >
                            <Link to={url.reports.payment.payment}>
                              Payment Detail
                            </Link>
                          </li>
                        </ul>
                      </li> */}
                      <li
                        className={
                          this.state.page === url.utilities.organisation
                            ? "active"
                            : null
                        }
                      ></li>
                      <li
                        className={
                          this.state.organisation === url.utilities.templates
                            ? "active"
                            : null
                        }
                      >
                        <Link
                          aria-expanded="true"
                          to={""}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-user"></i>
                          <span>Account</span>
                        </Link>
                        <ul className="collapse">
                          <li
                            className={
                              this.state.page === url.utilities.organisation
                                ? "active"
                                : null
                            }
                          >
                            <Link
                              to={url.utilities.accountDetails}
                              className="menuItem"
                            >
                              Account Details
                            </Link>
                          </li>
                          <li>
                            <a
                              href="#modal-org"
                              data-toggle="modal"
                              data-target="#modal-org"
                              className="nav-link"
                            >
                              Change Organisation
                            </a>
                          </li>

                          {/* <li
                            id="hide-content"
                            className={
                              this.state.page === url.utilities.users.list
                                ? "active"
                                : null
                            }
                          >
                            <Link to={url.utilities.users.list}>Users</Link>
                          </li> */}
                          <li>
                            <Link
                              to={""}
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleLogout();
                              }}
                            >
                              Log-out
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div
                  className="slimScrollBar"
                  style={{
                    background: "rgb(0, 0, 0)",
                    width: "7px",
                    position: "absolute",
                    top: "0px",
                    opacity: "0.4",
                    display: "none",
                    borderRadius: "7px",
                    zIndex: "99",
                    right: "1px",
                    height: "694px",
                  }}
                ></div>
                <div
                  className="slimScrollRail"
                  style={{
                    width: "7px",
                    height: "100%",
                    position: "absolute",
                    top: "0px",
                    display: "none",
                    borderRadius: "7px",
                    background: "rgb(51, 51, 51)",
                    opacity: "0.2",
                    zIndex: "90",
                    right: "1px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        ) : (
          <div className="sidebar-menu">
            <div className="sidebar-header">
              <div className="logo" style={{ height: "30px", color: "white" }}>
                <h2>Cloud Collect</h2>
              </div>
            </div>
            <div
              className="main-menu"
              style={{ margin: "-20px 20px 0px 20px", width: "90%" }}
            >
              <div className="menu-inner">
                <nav>
                  <ul className="metismenu" id="menu">
                    {/* <li
                      className={
                        this.state.page === url.customer.myProfile.myProfile
                          ? "active"
                          : null
                      }
                    >
                      <Link to={"/my-profile"} aria-expanded="true">
                        <i className="ti-dashboard"></i>
                        <span>My Profile</span>
                      </Link>
                    </li> */}
                    <li
                      className={
                        this.state.page === url.customer.myProfile.myInvoice
                          ? "active"
                          : null
                      }
                    >
                      <Link to={"/my-invoice"} aria-expanded="true">
                        <i className="fas fa-file-invoice-dollar"></i>
                        <span>Invoice</span>
                      </Link>
                    </li>
                    <li
                      className={
                        this.state.page === url.customer.mySubscriptions
                          ? "active"
                          : null
                      }
                    >
                      <Link to={"/my-subscriptions"} aria-expanded="true">
                        <i className="fas fa-file-invoice-dollar"></i>
                        <span>Subscriptions</span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={""}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleLogout();
                        }}
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
// const { fetchNotification } =  notificationAction;
// const mapDispatchToProps = dispatch => ({
//     fetchNotification: (payload, history) => fetchNotification({dispatch, payload, history}),
// });
const mapStateToProps = (state) => {
  return {
    allNotifications: state.Notifications,
  };
};
export default connect(mapStateToProps)(Sidebar);
