import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import DatePicker from "react-datepicker";
import moment from "moment";
import ColumnLoader from "../../uielements/columnLoader";
import DateDropdown from "../../DateDropdown/DateDropdown";
import { apiSocketHost } from "../../../config";
import { renderDate, renderSelect } from "../../uielements/FormFields";

const validate = (values) => {
  const errors = {};

  if (values.phone && values.phone.length !== 10) {
    errors.phone = "Phone number should be min 10 digits";
  }
  if (values.post_code && values.post_code.length !== 4) {
    errors.post_code = "Post code should be 4 digits";
  }
  // if (!values.abn) {
  //     errors.abn = 'Business abn is required'
  // }
  // if (!values.address) {
  //     errors.address = 'Address is required'
  // }
  // if (!values.mobile) {
  //     errors.mobile = 'mobile is required'
  // }
  // if (!values.contact_number) {
  //     errors.contact_number = 'mobile is required'
  // }
  // if (!values.mobile) {
  //     errors.mobile = 'Mobile is required'
  // }
  // if (!values.suburb) {
  //     errors.mobile = 'Suburb is required'
  // }
  // if (!values.post_code) {
  //     errors.mobile = 'Postcode is required'
  // }
  // if (!values.contact_number) {
  //     errors.contact_number = 'Contact Number is required'
  // }
  if (!values.email) {
    errors.email = "Contact email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Contact email is invalid";
  }

  return errors;
};

const renderField = ({
  input,
  label,
  type,
  readOnly,
  id,
  meta: { touched, error, warning },
}) => (
  <div>
    <label class="sr-only" for="inlineFormInputGroup"></label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text" id="input-label-large">
          {label}
        </div>
      </div>
      <input
        {...input}
        type={type}
        className="form-control"
        readOnly={readOnly}
        id={id}
      />
    </div>
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </div>
);

const UploadFile = ({
  name,
  input: { value: omitValue, ...inputProps },
  label,
  type,
  readOnly,
  id,
  meta: { touched, error, warning },
  ...props
}) => (
  <div>
    <label class="sr-only" for="inlineFormInputGroup"></label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text" id="input-label-large">
          {label}
        </div>
      </div>
      <div class="custom-file">
        <input
          class="custom-file-input"
          id="customFile"
          disabled={readOnly}
          type={type}
          {...inputProps}
          {...props}
        />
        <label class="custom-file-label" for="customFile">
          Attach
        </label>
      </div>
    </div>
    {omitValue && typeof omitValue == "string" && (
      <a target="_blank" href={`${apiSocketHost.fetchUrl}${omitValue}`}>
        <i class="fa fa-eye"></i> &nbsp; View Document
      </a>
    )}
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </div>
);
let DirectorForm = (props) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    directorDetailsEdit,
    handleEditClick,
    handleCancelCLicked,
    handleSaveCLicked,
    directorLoading,
    currentUser,
    organisationData,
    selectedDate,
  } = props;

  const states = [
    {
      label: "Australian Capital Territory",
      value: "Australian Capital Territory",
    },
    {
      label: "New South Wales",
      value: "New South Wales",
    },
    {
      label: "Northern Territory",
      value: "Northern Territory",
    },
    {
      label: "Queensland",
      value: "Queensland",
    },
    {
      label: "South Australia",
      value: "South Australia",
    },
    {
      label: "Tasmania",
      value: "Tasmania",
    },
    {
      label: "Victoria",
      value: "Victoria",
    },
    {
      label: "Western Australia",
      value: "Western Australia",
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <p class="onboard-heading">Payment Gateway</p>
      <hr />
      <div class="col-md-12 mt-3">
        <p>Enter owner/director or manager details to enable payment gateway.</p>
      </div>

      <div class="col-md-12 mt-3">
        <label class="sr-only" for="inlineFormInputGroup"></label>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text" id="input-label-large">
              Status
            </div>
          </div>
          <input
            type="text"
            class="form-control"
            placeholder={
              organisationData && organisationData.stripe_account_id
                ? "Active"
                : "Inactive"
            }
            disabled
          />
        </div>
      </div>
      <div class="col-md-12 mt-3">
        <Field
          label="First Name"
          type="text"
          id="first_name"
          name="first_name"
          // readOnly={!directorDetailsEdit}
          component={renderField}
        />
      </div>
      <div class="col-md-12 mt-3">
        <Field
          label="Last Name"
          type="text"
          id="last_name"
          name="last_name"
          // readOnly={!directorDetailsEdit}
          component={renderField}
        />
      </div>
      <div class="col-md-12 mt-3">
        <Field
          label="Email Address"
          type="email"
          id="email"
          name="email"
          // readOnly={!directorDetailsEdit}
          component={renderField}
        />
      </div>
      <div class="col-md-12 mt-3">
        <Field
          label="Mobile"
          type="number"
          id="phone"
          name="phone"
          // readOnly={!directorDetailsEdit}
          component={renderField}
        />
      </div>
      <div class="col-md-12 mt-3">
        <Field
          label="Job Title"
          type="text"
          id="title"
          name="title"
          // readOnly={!directorDetailsEdit}
          component={renderSelect}
          options={[
            {
              label: "Owner/Director",
              value: "Owner/Director",
            },
            {
              label: "Manager",
              value: "Manager",
            },
          ]}
        />
      </div>
      <div class="col-md-12 mt-3">
        <Field
          label="Address"
          type="text"
          id="line1"
          name="line1"
          // readOnly={!directorDetailsEdit}
          component={renderField}
        />
      </div>
      <div class="col-md-12 mt-3">
        <Field
          label="Suburb"
          type="text"
          id="city"
          name="city"
          // readOnly={!directorDetailsEdit}
          component={renderField}
        />
      </div>
      <div class="col-md-12 mt-3">
        <label class="sr-only" for="inlineFormInputGroup"></label>
        <div class="input-group mb-2">
          <Field
            label="State"
            id="state"
            name="state"
            component={renderSelect}
            options={states}
            // readOnly={!organisationDetailsEdit}
          />
        </div>
      </div>
      <div class="col-md-7 mt-3">
        <Field
          label="Postcode"
          type="number"
          id="post_code"
          name="post_code"
          // readOnly={!directorDetailsEdit}
          component={renderField}
        />
      </div>
      <div class="col-md-12 mt-3">
        <Field
          label="DOB"
          type="number"
          id="dob"
          name="dob"
          component={renderDate}
        />
      </div>

      

      {/* <div class="col-md-12 mt-3">
        <label>Gender</label>
        <div>
          <label>
            <Field name="gender" component="input" type="radio" value="male" />
            &nbsp;Male
          </label>
          &nbsp;&nbsp;
          <label>
            <Field
              name="gender"
              component="input"
              type="radio"
              value="female"
            />
            {"   "}
            Female
          </label>
          &nbsp;
        </div>
      </div> */}
      <div class="col-md-12 mt-3">
        <Field
          label="Driver's License"
          placeholder="Upload new"
          type={"file"}
          id="id_document"
          name="id_document"
          // readOnly={!directorDetailsEdit}
          component={UploadFile}
        />
      </div>
      <div class="col-md-12 mt-3">
        <Field
          label="License No."
          type="text"
          id="id_number"
          name="id_number"
          // readOnly={!directorDetailsEdit}
          component={renderField}
        />
      </div>
      <div class="col-md-12 mt-3">
        <Field
          label="Entity Name"
          type="text"
          id="entity_name"
          name="entity_name"
          // readOnly={!directorDetailsEdit}
          component={renderField}
        />
      </div>

      <div class="col-md-12 mt-3">
        <Field
          label="Business Identity"
          placeholder="Upload new"
          type={"file"}
          id="asic_document"
          name="asic_document"
          // readOnly={!directorDetailsEdit}
          component={UploadFile}
        />

        {organisationData && organisationData.asic_document && (
          <a
            target="_blank"
            href={`${apiSocketHost.fetchUrl}${organisationData.asic_document}`}
          >
            <i class="fa fa-eye"></i> &nbsp; View Document
          </a>
        )}
        <p>
          <small>
            ATO document detailing ABN, Address and Business Name per
            organisation settings. Such as ABN Registration.
          </small>
        </p>
      </div>
      {/* <div className='form-group'>
                        <Field
                            label="ID Document"
                            type='file'
                            id='id_document'
                            name='id_document'
                            readOnly={!directorDetailsEdit}
                            component={UploadFile}
                        />
                    </div> */}
      {/* {!directorDetailsEdit ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginTop: "5px",
          }}
        >
          {currentUser &&
            currentUser.primary_organisation &&
            currentUser.primary_organisation.id ===
              currentUser.default_organisation_id && (
              <button
                type="button"
                className="btn btn-secondary"
                style={{ width: "150px" }}
                onClick={(event) => {
                  event.preventDefault();
                  handleEditClick("director");
                }}
              >
                Edit
              </button>
            )}
        </div>
      ) : ( */}
      <>
        <hr />
        <div className="invoice-buttons text-center">
          <button type="submit" className="btn btn-success">
            Update
          </button>
        </div>
      </>
      {/* <div className="form-buttons-group">
           <button */}
      {/* //     type="submit"
        //     className="btn btn-secondary"
        //     style={{ width: "100px" }}
        //   >
        //     Save
        //   </button> */}
      {/* //   <button */}
      {/* //     type="button"
        //     className="btn btn-secondary"
        //     style={{ width: "100px" }}
        //     onClick={(event) => {
        //       event.preventDefault();
        //       handleCancelCLicked("director");
        //     }}
        //   >
        //     Cancel
        //   </button>
        // </div> */}
      {/* )} */}
    </form>
  );
};

DirectorForm = reduxForm({
  form: "director",
  validate,
  enableReinitialize: true,
})(DirectorForm);

export default DirectorForm;
