import React, { Component } from "react";
import { connect } from "react-redux";
import SuperFetch from "../../helpers/superFetch";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactName: "",
    };
  }

  componentWillUnmount = () => {
    this.setState({ contactName: "" });
  };

  createHeaderTitle() {
    const { contactName, invoice, product, proposal } = this.props;
    let title = "";
    let id = "";
    if (this.props) {
      let url = this.props.history.location.pathname.split("/");
      if (url[3]) id = url[3];
      if (this.props.history.location.pathname === "/") title = "Dashboard";
      if (this.props.history.location.pathname === "/dashboard")
        title = "Dashboard";
      if (this.props.history.location.pathname === "/contacts")
        title = "Contacts";
      if (this.props.history.location.pathname === "/newLead")
        title = "Create Contact";
      if (this.props.history.location.pathname === "/lead/edit/" + id)
        title = `Contact - ${contactName ? contactName : ""}`;
      if (this.props.history.location.pathname === "/invoices")
        title = "Invoices";
      if (this.props.history.location.pathname === "/invoice/add")
        title = "New Invoice";
      if (this.props.history.location.pathname === "/invoice/edit/" + id)
        title = "Edit Invoice";
      if (this.props.history.location.pathname === "/invoice/non-edit/" + id)
        title = `Invoice #${invoice ? invoice.invoice_number : ""}`;
      if (this.props.history.location.pathname === "/invoice/payment-plan" + id)
        title = "Invoice";
      if (this.props.history.location.pathname === "/invoice/add/product")
        title = "New Invoice";
      if (this.props.history.location.pathname === "/proposal")
        title = "Proposals";
      if (this.props.history.location.pathname === "/proposal/add")
        title = "New Proposal";
      if (this.props.history.location.pathname === "/proposal/add/product")
        title = "New Proposal";
      if (this.props.history.location.pathname === "/proposal/edit/" + id)
        title = "Edit Proposal";
      if (this.props.history.location.pathname === "/proposal/non-edit/" + id)
        title = `Proposal #${proposal ? proposal.proposal_reference : ""}`;
      if (this.props.history.location.pathname === "/products")
        title = "Products";
      if (this.props.history.location.pathname === "/products/add")
        title = "New Product";
      if (this.props.history.location.pathname === "/products/edit/" + id)
        title = `Product - ${product && product.name ? product.name : ""}`;
      if (this.props.history.location.pathname === "/flow")
        title = "Debtor Control";
      if (this.props.history.location.pathname === "/debtor-reports")
        title = "Report - Debtors Detailed";
      if (this.props.history.location.pathname === "/reports")
        title = "Reports";
      if (this.props.history.location.pathname === "/debtor-summary")
        title = "Report - Debtors Summary";
      if (this.props.history.location.pathname === "/income-report")
        title = "Report - Income Detailed";
      if (this.props.history.location.pathname === "/income-summary")
        title = "Report - Income Summary";
      if (this.props.history.location.pathname === "/payment-report")
        title = "Payments";
      if (this.props.history.location.pathname === "/payment-summary")
        title = "Report - Payments Summary";
      if (this.props.history.location.pathname === "/earning-reports")
        title = "Earning Reports";
      if (this.props.history.location.pathname === "/performance-reports")
        title = "Performance Reports";
      if (this.props.history.location.pathname === "/finance")
        title = "Finance";
      if (this.props.history.location.pathname === "/templates")
        title = "Communications";
      if (this.props.history.location.pathname === "/organisation")
        title = "Organisation Details";
      if (this.props.history.location.pathname === "/settings")
        title = "Settings";
      if (this.props.history.location.pathname === "/notifications")
        title = "Notifications";
      if (this.props.history.location.pathname === "/users") title = "Users";
      if (this.props.history.location.pathname === "/my-profile")
        title = "Profile";
      if (this.props.history.location.pathname === "/files") title = "Files";
      if (this.props.history.location.pathname === "/integration")
        title = "Integration";
      if (this.props.history.location.pathname === "/support-center")
        title = "Support Center";
      if (this.props.history.location.pathname === "/contact")
        title = "Contact";
      if (this.props.history.location.pathname === "/support-page")
        title = "Support";
      if (this.props.history.location.pathname === "/my-invoice")
        title = "Invoices";
      if (this.props.history.location.pathname === "/my-subscriptions")
        title = "Subscriptions";
      if (this.props.history.location.pathname === "/admin/businesses")
        title = "Businesses";
      if (this.props.history.location.pathname === "/admin/customers")
        title = "Customers";
      if (this.props.history.location.pathname === "/admin/terms&conditions")
        title = "Terms & Conditions";
      if (this.props.history.location.pathname === "/account-details")
        title = "Account Details";
    }
    return title;
  }

  render() {
    console.log("Location", this.props.history.location);
    return (
      <div className="header-area">
        <div className="row align-items-center">
          <div className="col-md-6 col-sm-8 clearfix">
            <div className="close-btn nav-btn pull-left">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="breadcrumbs-area clearfix" id="h-heading">
              <h4 class="page-title pull-left">
                <strong>{this.createHeaderTitle()}</strong>
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contactName: state.Contacts.selectedContact,
    invoice: state.Invoices.invoice,
    product: state.Product.product,
    proposal: state.Proposals.proposal,
  };
};

export default connect(mapStateToProps)(Header);
