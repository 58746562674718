import React from "react";
import DatePicker from "react-datepicker";
import SuperFetch from "../../../helpers/superFetch";
import PaymentPlan from "./PaymentPlan/index";
import $ from "jquery";
import NumberFormat from "react-number-format";
import { store } from "react-notifications-component";
import {
  formattedAmount,
  calculateGstHelper,
  renderTaxType,
  calcTwoDecimalPlaces,
  formatTwoDecimals,
  formatTwoDecimalsWithComma,
} from "../../../_methods/commonMethods";
import HistorySection from "./HistorySection";
import { connect } from "react-redux";
import InvoiceActions from "../../../redux/invoice/action";
import { Link, Redirect } from "react-router-dom";
import { apiHost, apiSocketHost } from "../../../config";
import axios from "axios";
import DraftComponent from "../../DraftComponent";

class NonEditInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      showModal: true,
      invoice_date: "",
      invoice_due_date: "",
      notes: null,
      attachments: [],
      payment_date: "",
      invoice_rows: [],
      total: 0,
      amount: 0,
      gst: 0,
      net: 0,
      organisation_name: "",
      address: "",
      suburb: "",
      state: "",
      postcode: "",
      email: "",
      id: "",
      invoice_number: "",
      uuid: "",
      currentLead: {
        first_name: "",
        last_name: "",
      },
      all_data: [],
      message: "",
      business_name: "",
      sms: false,
      pdf: false,
      message_err: false,
      email_err: false,
      modal: false,
      email_correct_err: false,
      markAsPaidError: null,
      plan_frequency: "",
      plan_upfront: "",
      plan_first_payment: "",
      plan_last_payment: "",
      plan_repaymant_amount: "",
      plan_total: "",
      max_term: "",
      auto_accept_payment_plan: "",
      plan_status: "",
      invoice_status: "",
      paid: 0,
      balance: 0,
      no_of_payments: 0,
      plan_details: [],
      payment_plan: null,
      paid_date: new Date(),
      organisation: null,
      status: null,
      reminder_enabled: 0,
    };
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.openPreviewPage = this.openPreviewPage.bind(this);
    this.handleSendInvoice = this.handleSendInvoice.bind(this);
    this.handleAbnSave = this.handleAbnSave.bind(this);
    this.handleSms = this.handleSms.bind(this);
    this.handlePdf = this.handlePdf.bind(this);
  }

  componentDidMount() {
    document.title = "Cloud Collect - Invoice";
    this.getFlow();
    this.getInvoiceData();

    this.setMessageTemplateHandler();
  }

  setMessageTemplateHandler = () => {
    SuperFetch.get("/templates").then((response) => {
      this.setState({
        message: response.data.template_data.invoice
          ? response.data.template_data.invoice
          : "<p></p>",
      });
    });
  };

  getInvoiceData = () => {
    const { selectedContact } = this.props;
    let id = this.props.match.params.id;
    this.setState({ id: id });
    SuperFetch.get("/invoices/" + id).then((response) => {
      this.initInvoice(response.data);
      this.dataState(response.data);
      selectedContact({ payload: response.data });
      this.getPaymentPlan(
        response.data.lead.organisation.business_name,
        response.data.invoice_number
      );
    });
  };

  handleSms() {
    this.setState({ sms: !this.state.sms });
  }

  handlePdf() {
    this.setState({ pdf: !this.state.pdf });
  }

  changeToNumber(item) {
    let number = null;
    if (item === true) number = 1;
    if (item === false) number = 0;
    return number;
  }

  validate() {
    let error = false;
    if (this.state.message === "") this.state.message_err = true;
    if (this.state.email === "") {
      this.state.email_err = true;
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
        this.state.email_correct_err = true;
      } else {
        this.state.email_correct_err = false;
        this.setState({
          email_correct_err: false,
        });
      }
    }
    if (
      this.state.message_err ||
      this.state.email_err ||
      this.state.email_correct_err
    )
      error = true;
    return error;
  }

  handleSendInvoice() {
    if (this.validate()) {
      this.setState({
        modal: true,
      });
      return;
    } else {
      this.setState({
        modal: true,
      });

      let data = {
        name: this.state.name,
        mobile: this.state.mobile,
        email: this.state.email,
        message: this.state.message,
        send_sms: this.changeToNumber(this.state.sms),
        include_pdf: this.changeToNumber(this.state.pdf),
        link: "/invoice/" + this.state.uuid,
      };
      SuperFetch.post("/invoices/send/" + this.state.invoice_number, data).then(
        (response) => {
          this.getInvoiceData();
          store.addNotification({
            message: "Invoice sent successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          this.setState({ message: "" });
          this.setMessageTemplateHandler();

          $("#modal-org-invoice").find("button.close").click();
        }
      );
    }
  }

  handleAbnSave() {
    let data = {
      abn: this.state.abn,
    };
    SuperFetch.post(
      "/organisation/update/" + this.state.organisation.id,
      data
    ).then((response) => {
      this.getInvoiceData();
      store.addNotification({
        message: "Business ABN updated successfully.",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      this.setState({ message: "" });
      this.setMessageTemplateHandler();

      $("#modal-abn").find("button.close").click();
    });
  }

  handlePaidDate = (e) => {
    this.setState({
      paid_date: new Date(e),
      markAsPaidError: null,
    });
  };

  validateMarkAsPaid = () => {
    let error = false;
    if (!this.state.paid_date) {
      error = true;
      this.setState({ markAsPaidError: "Paid Date is required" });
    }

    return error;
  };

  changePaidDate(data) {
    let date = data.getDate();
    let month = data.getMonth() + 1;
    let year = data.getFullYear();
    return this.addZero(date) + "-" + this.addZero(month) + "-" + year;
  }

  handleMarkAsPaid = () => {
    const { match } = this.props;

    if (!this.validateMarkAsPaid()) {
      let data = {
        status: "Paid",
        invoice_id: match.params.id,
        payment_date: this.changePaidDate(this.state.paid_date),
      };
      axios
        .post(`${apiHost.fetchUrl}/invoice-status-update`, data, {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("id_token") || undefined,
          },
        })
        .then((response) => {
          store.addNotification({
            message: "Status changed successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          this.setState({
            paid_date: null,
          });
          this.getInvoiceData();
          $("#modal-org-markAsPaid").find("button.close").click();
        })
        .catch((error) => {
          store.addNotification({
            message: "Something went wrong. Please try after some time.",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        });
    }
  };

  markAsUnpaidHandler = () => {
    const { match } = this.props;

    let data = {
      status: "Unpaid",
      invoice_id: match.params.id,
    };
    axios
      .post(`${apiHost.fetchUrl}/invoice-status-update`, data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("id_token") || undefined,
        },
      })
      .then((response) => {
        store.addNotification({
          message: "Status changed successfully.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        this.setState({
          paid_date: null,
        });
        this.getInvoiceData();
      })
      .catch((error) => {
        store.addNotification({
          message: "Something went wrong. Please try after some time.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
  };

  changeStatusToArchived = () => {
    const { match, history } = this.props;

    let data = { status: "Archived", invoice_id: match.params.id };
    axios
      .post(`${apiHost.fetchUrl}/invoice-status-update`, data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("id_token") || undefined,
        },
      })
      .then((response) => {
        if (this.state.invoice_status == "Draft") {
          store.addNotification({
            message: "Draft invoice removed successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          history.push("/invoices");
        } else {
          store.addNotification({
            message: "Status changed successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          this.setState({
            paid_date: null,
          });
          this.getInvoiceData();
        }
      })
      .catch((error) => {});
  };

  handleShowModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  handleInputEmail(e) {
    this.setState({
      email: e.target.value,
      email_err: false,
    });
  }

  onMobileChangeHandler = (e) => {
    this.setState({
      mobile: e.target.value,
    });
  };

  nameChangeHandler = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  abnChangeHandler = (e) => {
    this.setState({
      abn: e.target.value,
    });
  };

  handleInputMessage(e) {
    this.setState({
      message: e.target.value,
      message_err: false,
    });
  }

  handleEdit() {
    this.props.history.push("/invoice/edit/" + this.state.id);
  }

  dataState(response) {
    let id = this.props.match.params.id;
    this.setState({
      organisation: response.lead.organisation,
      organisation_name: response.lead.company_name,
      address: response.lead.address,
      suburb: response.lead.suburb,
      state: response.lead.state,
      postcode: response.lead.postcode,
      email: response.lead.email,
      id: id,
      all_data: response,
      business_name: response.lead.organisation.business_name,
    });
  }

  getFlow() {
    SuperFetch.get("/flows").then((response) => {
      this.setState({
        max_term: response.data.maximum_terms,
        auto_accept_payment_plan: response.data.auto_accept_payment_plan,
      });
    });
  }

  getPaymentPlan(business_name, number) {
    SuperFetch.get(
      "/invoices/by-reference/" + business_name + "/" + number
    ).then((response) => {
      if (response.data)
        this.setState({
          invoice_status: response.data.status,
          // gst: response.data.gst,
        });
      if (response && response.data && response.data.payment_plan)
        this.setState({
          payment_plan: response.data.payment_plan,
          plan_frequency: response.data.payment_plan.frequency,
          plan_first_payment: response.data.payment_plan.first_payment,
          plan_repaymant_amount: response.data.payment_plan.amount,
          plan_total: response.data.payment_plan.balance,
          plan_id: response.data.payment_plan.id,
          plan_status: response.data.payment_plan.status,
          plan_upfront: response.data.payment_plan.upfront,
          no_of_payments: response.data.payment_plan.no_of_payments,
          plan_details: response.data.payment_plan.details,
        });
    });
  }

  changeDate(data) {
    let newDate = new Date(data);
    let formattedDate;
    if (newDate === "Invalid Date") {
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      formattedDate =
        this.addZero(date) + "/" + this.addZero(month) + "/" + year;
    } else {
      let array = data.split("-");

      formattedDate = array[0] + "/" + array[1] + "/" + array[2];
    }

    return formattedDate;
  }

  addZero(day) {
    day = parseInt(day).toString();
    if (day.length === 1) day = "0" + day;
    return day;
  }

  initInvoice(data) {
    let rows = [];

    for (let item of data.items) {
      let obj = {
        id: item.id,
        name: item.name,
        description: item.description,
        unit_amount: parseFloat(item.amount),
        quantity: item.quantity,
        tax_rate: item.gst_free,
        amount: 0,
        product_id: item.product_id,
      };
      rows.push(obj);
    }

    console.log("data", data);

    this.setState({
      invoice_date: data.invoice_date,
      invoice_due_date: data.due_date,
      payment_date: data.payment_date,
      invoice_number: data.invoice_number,
      paid_date: data.paid_date,
      uuid: data.uuid,
      lead: data.lead_id,
      paid: data.paid,
      balance: data.balance,
      total: data.total,
      amount: data.amount,
      attachments: data.attachments,
      notes: data.notes,
      name: data.lead.full_name,
      mobile: data.lead.mobile,
      gst: data.gst,
      status: data.status,
      reminder_enabled: data.reminder_enabled,
    });
    this.calculatePrices(rows);
    this.getLeads(data.lead_id);
  }

  getLeads(id) {
    SuperFetch.get("/leads").then((response) => {
      let lead;
      for (let item of response.data) {
        if (item.id === id) lead = item;
      }
      if (lead) this.setState({ currentLead: lead });
    });
  }

  calculatePrices(baseTmp) {
    let net = null;
    let gst = null;
    let total = null;
    let netWithGST = null;
    for (let i = 0; i < baseTmp.length; i++) {
      if (baseTmp[i].unit_amount) {
        baseTmp[i].amount = baseTmp[i].unit_amount * baseTmp[i].quantity;
        net += baseTmp[i].amount;
        if (parseInt(baseTmp[i].tax_rate) === 0) {
          netWithGST = baseTmp[i].amount;
        }
      }

      // gst += baseTmp[i].gst_free === 1 ? 0 : (net * 10) / 100;
      gst += calculateGstHelper(netWithGST, baseTmp[i].tax_rate);
      total = net + gst;
      // total = net + gst;
    }
    this.setState({
      invoice_rows: baseTmp,
      net: net,
      gst: gst,
      total: total,
    });
  }

  downloadPdfHandler = () => {
    const { selectedInvoice } = this.props;
    window.open(
      apiSocketHost.fetchUrl + "/invoice/pdf/" + `${selectedInvoice.uuid}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  openPreviewPage(e) {
    let url = "/invoice/" + this.state.uuid;
    e.preventDefault();
    window.open(url);
    // {event.preventDefault(); window.open(this.makeHref("route"))
  }

  openCopyPage = async () => {
    const { selectedInvoice } = this.props;

    await localStorage.setItem(
      "copy_products",
      JSON.stringify(selectedInvoice.items)
    );

    this.props.history.push(`/invoice/add/product?products=copy`);
  };

  handleAccept() {
    let data = {
      status: "Accepted",
    };
    SuperFetch.post(
      "/invoices/accept/payment-plan/" + this.state.plan_id,
      data
    ).then((response) => {
      this.addNotification("Payment Plan Accepted", "success");

      this.setState({ plan_status: "Accepted" });
      this.getInvoiceData();
    });
  }

  handleReject() {
    let data = {
      status: "Declined",
    };
    SuperFetch.post(
      "/invoices/accept/payment-plan/" + this.state.plan_id,
      data
    ).then((response) => {
      this.addNotification("Payment Plan Declined", "success");
      this.setState({ plan_status: "Declined" });
      this.getInvoiceData();
    });
  }

  addNotification = (message, type) => {
    store.addNotification({
      message: message,
      type,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };

  getChangeDate(data) {
    if (data) {
      let date = data.split("-");
      return date[0] + "/" + date[1] + "/" + date[2];
    }
    return;
  }

  enableReminder = (e) => {
    this.setState({
      reminder_enabled: e.target.checked ? 1 : 0,
    });
    let data = {
      id: this.state.id,
      reminder_enabled: e.target.checked ? 1 : 0,
    };
    SuperFetch.post("/invoices/updateInput", data).then((response) => {
      // this.addNotification("Reminder updated", "success");
    });
  };

  editorValue = (value) => {
    this.setState({ message: value, error: false });
  };

  renderTable = () => {
    let balance = 0;
    this.state.plan_details.forEach((detail) => {
      balance += parseFloat(detail.amount);
    });
    return (
      <tbody>
        <tr>
          <td>-</td>
          <td>Invoice</td>
          <td>{this.state.invoice_date}</td>
          <td>-</td>
          <td>
            <NumberFormat
              value={parseFloat(this.state.total).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              renderText={(value) => <span>{value}</span>}
            />
          </td>
          <td>
            <NumberFormat
              value={parseFloat(balance).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              renderText={(value) => <span>{value}</span>}
            />
          </td>
        </tr>
        {this.state.plan_details &&
          this.state.plan_details.map((detail, key) => {
            balance = balance - detail.amount;
            return (
              <tr>
                <td>{detail.number}</td>
                <td>{detail.type}</td>
                <td>{detail.due_date}</td>
                <td>{detail.status}</td>
                <td>
                  <NumberFormat
                    value={parseFloat(detail.amount).toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"-$"}
                    renderText={(value) => <span>{value}</span>}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={parseFloat(balance).toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    renderText={(value) => <span>{value}</span>}
                  />{" "}
                </td>
              </tr>
            );
          })}
      </tbody>
    );
  };

  render() {
    const { selectedInvoice } = this.props;
    let { organisation } = this.state;
    console.log(selectedInvoice);
    return (
      <div className="main-content-inner">
        {this.state.invoice_status &&
        this.state.invoice_status === "Plan" &&
        this.state.plan_status === "Pending" ? (
          this.state.plan_status === "Accepted" ||
          this.state.plan_status === "Declined" ? (
            ""
          ) : (
            <div className="row">
              <div className="col-lg-12 mt-3">
                <div className="card">
                  <div className="card-body">
                    <p>
                      <strong>
                        Customer has requested the following payment plan:
                      </strong>
                    </p>
                    <p>Number Of Payments: {this.state.no_of_payments} </p>
                    <p>Payment Frequency: {this.state.plan_frequency}</p>
                    <p>
                      Upfront Payment: $
                      {this.state.plan_upfront
                        ? parseFloat(this.state.plan_upfront).toFixed(2)
                        : parseFloat(0).toFixed(2)}
                    </p>
                    <p>
                      First Payment:{" "}
                      {this.state.plan_first_payment
                        ? this.changeDate(this.state.plan_first_payment)
                        : ""}
                    </p>
                    <p>
                      Repayments: $
                      {this.state.plan_repaymant_amount
                        ? parseFloat(this.state.plan_repaymant_amount).toFixed(
                            2
                          )
                        : parseFloat(0).toFixed(2)}
                    </p>
                    <p>
                      Total Repaid: $
                      {this.state.plan_total
                        ? parseFloat(this.state.plan_total).toFixed(2)
                        : parseFloat(0).toFixed(2)}
                    </p>
                    <br />
                    <div className="invoice-buttons">
                      <button
                        onClick={this.handleAccept.bind(this)}
                        type="button"
                        className="btn btn_reject_color"
                      >
                        Accept
                      </button>
                      <button
                        onClick={this.handleReject.bind(this)}
                        type="button"
                        className="btn btn_reject_color"
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-lg-10 mt-3">
            <div className="card">
              {this.state.status && this.state.status != "" && (
                <div
                  className={`card-header card-${this.state.status.toLowerCase()}`}
                >
                  {this.state.status == "Plan"
                    ? "Payment Plan"
                    : this.state.status}
                </div>
              )}
              <div className="card-body">
                <div className="invoice-area">
                  {/* <div class="invoice-head">
                    <div class="row">
                      <div class="iv-left col-6">
                        <h4>Invoice #{this.state.invoice_number}</h4>
                      </div>
                      <div class="iv-right col-6 text-md-right">
                        <h4>{this.state.invoice_status}</h4>
                      </div>
                    </div>
                  </div> */}
                  <div className="row">
                    <div class="col-md-3 col-sm-12">
                      <label class="sr-only" for="inlineFormInputGroup">
                        Contact
                      </label>
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Contact</div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder=""
                          defaultValue={
                            this.state.currentLead
                              ? this.state.currentLead.full_name
                              : ""
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-12">
                      <label class="sr-only" for="inlineFormInputGroup">
                        Reference
                      </label>
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Reference</div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder=""
                          defaultValue={this.state.invoice_number}
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-12">
                      <label class="sr-only" for="inlineFormInputGroup">
                        Date
                      </label>
                      <div class="input-group mb-2 date">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Date</div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder=""
                          defaultValue={this.state.invoice_date}
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-12">
                      <label class="sr-only" for="inlineFormInputGroup">
                        Expires
                      </label>
                      <div class="input-group mb-2 date">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Due</div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder=""
                          defaultValue={this.state.invoice_due_date}
                          disabled
                        />
                      </div>
                    </div>
                    {/* <p>
                        {this.state.currentLead
                          ? this.state.currentLead.full_name
                          : ""}
                      </p> */}
                    {/* <p class="text-capitalize">{this.state.address}</p>
                    <p class="text-capitalize">
                      {this.state.suburb} {this.state.state}{" "}
                      {this.state.postcode}
                    </p>
                    <div class="col-md-6 text-md-right" id="form-dates">
                      <p>Invoice Date : {this.state.invoice_date}</p>
                      <p>Due Date : {this.state.invoice_due_date}</p>
                      {this.state.payment_date && (
                        <p>Paid Date : {this.state.payment_date}</p>
                      )}
                    </div> */}
                  </div>
                  <br />
                  <div class="row" id="hide-mobile">
                    <div class="col-12">
                      <div id="table" className="table-editable">
                        <table className="table table-bordered table-responsive-md text-center">
                          <thead>
                            <tr>
                              <th className="text-left">Product ID</th>
                              <th className="text-left">Description</th>
                              <th>Units</th>
                              <th>Unit Price</th>
                              <th>Tax Rate</th>
                              <th>Total Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.invoice_rows &&
                              this.state.invoice_rows.map((item) => {
                                return (
                                  <tr key={item.id}>
                                    <td
                                      className="pt-3-half"
                                      contenteditable="false"
                                      style={{ textAlign: "left" }}
                                      width="125"
                                    >
                                      {item.name}
                                    </td>
                                    <td
                                      className="pt-3-half"
                                      contenteditable="false"
                                      style={{ textAlign: "left" }}
                                    >
                                      {item.description}
                                    </td>
                                    <td
                                      className="pt-3-half"
                                      contenteditable="false"
                                      style={{ textAlign: "right" }}
                                      width="125"
                                    >
                                      {parseFloat(item.quantity)}
                                    </td>
                                    <td
                                      className="pt-3-half"
                                      contenteditable="false"
                                      style={{ textAlign: "right" }}
                                      width="125"
                                    >
                                      <NumberFormat
                                        value={parseFloat(
                                          item.unit_amount
                                        ).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    </td>
                                    <td
                                      className="pt-3-half"
                                      contenteditable="false"
                                      style={{ textAlign: "center" }}
                                      width="125"
                                    >
                                      {renderTaxType(item.tax_rate)}
                                    </td>
                                    <td
                                      className="pt-3-half"
                                      contenteditable="false"
                                      style={{ textAlign: "right" }}
                                      width="125"
                                    >
                                      {item.amount ? (
                                        <NumberFormat
                                          value={parseFloat(
                                            item.amount
                                          ).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                          renderText={(value) => (
                                            <span>{value}</span>
                                          )}
                                        />
                                      ) : (
                                        "0.00"
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}

                            {/* <tr class="table-totals">
                          <td colspan="6">
                            PAID: $
                            {this.state.paid
                              ? parseFloat(this.state.paid).toFixed(2)
                              : "0.00"}
                          </td>
                        </tr>
                        <tr class="table-totals">
                          <td colspan="6">
                            BALANCE: $
                            {this.state.balance
                              ? parseFloat(this.state.balance).toFixed(2)
                              : "0.00"}
                          </td>
                        </tr> */}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="4"></td>
                              <td>Sub-Total: </td>
                              <td class="text-right">
                                {this.state.net ? (
                                  <NumberFormat
                                    value={parseFloat(this.state.net).toFixed(
                                      2
                                    )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                ) : (
                                  "0.00"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4"></td>
                              <td>Total GST:</td>
                              <td class="text-right">
                                {this.state.gst ? (
                                  <NumberFormat
                                    value={parseFloat(this.state.gst).toFixed(
                                      2
                                    )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                ) : (
                                  "0.00"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4"></td>

                              <td>
                                <h5>Total: </h5>
                              </td>
                              <td class="text-right">
                                <h5>
                                  {this.state.total ? (
                                    <NumberFormat
                                      value={parseFloat(
                                        this.state.total
                                      ).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      renderText={(value) => (
                                        <span>{value}</span>
                                      )}
                                    />
                                  ) : (
                                    "0.00"
                                  )}
                                </h5>
                              </td>
                            </tr>
                            {this.state.balance !== this.state.total && (
                              <>
                                <tr>
                                  <td colspan="4"></td>

                                  <td>Payments:</td>
                                  <td class="text-right">
                                    {this.state.paid ? (
                                      <NumberFormat
                                        value={parseFloat(
                                          this.state.paid
                                        ).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    ) : (
                                      "0.00"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4"></td>

                                  <td>
                                    <h5>Due: </h5>
                                  </td>
                                  <td class="text-right">
                                    <h5>
                                      {this.state.balance ? (
                                        <NumberFormat
                                          value={parseFloat(
                                            this.state.balance
                                          ).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                          renderText={(value) => (
                                            <span>{value}</span>
                                          )}
                                        />
                                      ) : (
                                        "0.00"
                                      )}
                                    </h5>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>

                <div class="row" id="hide-mobile">
                  <div class="col-md-6 mt-1">
                    <div class="form-group">
                      <label for="exampleFormControlTextarea1"></label>
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Notes</div>
                        </div>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="8"
                          defaultValue={this.state.notes}
                          disabled
                        ></textarea>
                      </div>
                    </div>

                    {/* <div class="input-group mb-2">
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            disabled
                          />
                          <label class="custom-file-label" for="customFile">
                            Attach file
                          </label>
                        </div>
                      </div> */}
                  </div>
                  <div class="col-md-6 mt-4">
                    {this.state.attachments.map((file2) => (
                      <div className="row">
                        <div title={file2.file_name} className="col-md-12">
                          <a
                            target="blank"
                            href={`${apiSocketHost.fetchUrl}/storage/${file2.id}/${file2.file_name}`}
                          >
                            {file2.file_name.length > 30
                              ? file2.file_name.substr(0, 30) + "..."
                              : file2.file_name}{" "}
                          </a>
                        </div>

                        {/* <i
                            style={{ cursor: "pointer" }}
                            onClick={() => this.fileDelteHandler(file2)}
                            class="fas fa-trash"
                          ></i> */}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-check">
                      {console.log(
                        selectedInvoice.reminder_enabled === 1 ? true : false
                      )}
                      <input
                        type="checkbox"
                        id="customCheck1"
                        name="enable_reminder"
                        onChange={(e) => {
                          this.enableReminder(e);
                        }}
                        checked={
                          this.state.reminder_enabled === 1 ? true : false
                        }
                        className="form-check-input"
                        value={this.state.reminder_enabled}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="customCheck1"
                      >
                        Enable reminders per Debtor Control Setting
                      </label>
                    </div>
                  </div>
                </div>
                <div id="hide-mobile">
                  {selectedInvoice.activity_logs &&
                    selectedInvoice.activity_logs.length > 0 && (
                      <HistorySection logs={selectedInvoice.activity_logs} />
                    )}
                </div>

                <div className="row" id="hide-desktop">
                  <p style={{ fontSize: 16 }}>Invoice Items</p>
                  <table className="table">
                    <tbody>
                      {this.state.invoice_rows.map((element, index) => {
                        return element.name || element.description ? (
                          <tr key={index}>
                            <td style={{ whiteSpace: "pre-wrap" }} id="myBtn-1">
                              {element.description} <br />
                              {element.quantity}X{" "}
                              {element.unit_amount &&
                                "$" +
                                  formatTwoDecimalsWithComma(
                                    parseFloat(element.unit_amount)
                                  )}{" "}
                              (
                              {parseInt(element.tax_rate) === 1
                                ? "GST Free"
                                : "GST"}
                              )
                            </td>
                          </tr>
                        ) : null;
                      })}
                    </tbody>
                  </table>
                </div>
                <div class="row" id="hide-desktop">
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td>Sub-Total</td>
                        <td style={{ textAlign: "right" }}>
                          <NumberFormat
                            value={formatTwoDecimals(this.state.net)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Total GST</td>
                        <td style={{ textAlign: "right" }}>
                          <NumberFormat
                            value={formatTwoDecimals(this.state.gst)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td style={{ textAlign: "right" }}>
                          <NumberFormat
                            value={formatTwoDecimals(this.state.total)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row" id="hide-desktop">
                  <p>
                    <small>Attached Files:</small>
                  </p>
                </div>
                <div class="row" id="hide-desktop">
                  {this.state.attachments.map((file2) => (
                    <p>
                      <small>
                        -
                        <a
                          target="blank"
                          href={`${apiSocketHost.fetchUrl}/storage/${file2.id}/${file2.file_name}`}
                        >
                          {file2.file_name.length > 30
                            ? file2.file_name.substr(0, 30) + "..."
                            : file2.file_name}{" "}
                        </a>
                      </small>
                    </p>
                  ))}
                </div>
                {/* <div className="invoice-buttons invoice-buttons-invoice text-center">
                  {this.state.invoice_status === "Paid" ||
                  this.state.invoice_status === "Archived" ||
                  this.state.invoice_status === "Plan" ||
                  this.state.invoice_status === "Later" ? null : (
                    <button
                      onClick={this.handleEdit.bind(this)}
                      type="button"
                      className="btn btn-secondary btn-non-edit-invoice"
                      disabled={
                        this.state.invoice_status === "Paid" ||
                        this.state.invoice_status === "Archived" ||
                        this.state.invoice_status === "Plan" ||
                        this.state.invoice_status === "Later"
                          ? true
                          : false
                      }
                    >
                      Edit
                    </button>
                  )}
                  <button
                    onClick={this.openPreviewPage}
                    type="button"
                    className="btn btn-secondary text-white btn-non-edit-invoice"
                  >
                    View
                  </button> */}
                {/*<button type="button" onClick={this.handleShowModal}*/}
                {/*className="btn btn-secondary  btn-non-edit-invoice">Send*/}
                {/*</button>*/}
                {/* <a
                    href="#modal-org-invoice"
                    data-toggle="modal"
                    data-target="#modal-org-invoice"
                    className="btn btn-secondary  btn-non-edit-invoice"
                  >
                    Send
                  </a>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-lg-2 mt-3">
            <div className="card">
              <div className="card-body">
                <div className="invoice-buttons text-center">
                  {selectedInvoice.status === "Archived" ||
                  selectedInvoice.status === "Paid" ? null : (
                    <button
                      type="button"
                      className="btn btn-success"
                      data-toggle="modal"
                      data-target={
                        organisation && !organisation.abn
                          ? "#modal-abn"
                          : "#modal-org-invoice"
                      }
                      style={{ width: "150px" }}
                    >
                      Send
                    </button>
                  )}
                  {selectedInvoice.status === "Archived" ||
                  selectedInvoice.status === "Paid" ? null : (
                    <button
                      type="button"
                      className="btn btn-secondary new-proposal-button"
                      style={{ width: "150px" }}
                      onClick={this.handleEdit.bind(this)}
                      disabled={
                        this.state.invoice_status === "Paid" ||
                        this.state.invoice_status === "Archived" ||
                        this.state.invoice_status === "Plan" ||
                        this.state.invoice_status === "Later"
                          ? true
                          : false
                      }
                    >
                      Edit
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-secondary new-proposal-button"
                    style={{ width: "150px" }}
                    onClick={this.openCopyPage}
                  >
                    Copy
                  </button>
                  {organisation && !organisation.abn ? (
                    <button
                      type="button"
                      className="btn btn-secondary new-proposal-button"
                      style={{ width: "150px" }}
                      data-toggle="modal"
                      data-target={
                        organisation && !organisation.abn
                          ? "#modal-abn"
                          : "#modal-org-invoice"
                      }
                    >
                      View
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-secondary new-proposal-button"
                      style={{ width: "150px" }}
                      onClick={this.openPreviewPage}
                    >
                      View
                    </button>
                  )}

                  {organisation && !organisation.abn ? (
                    <button
                      type="button"
                      className="btn btn-secondary new-proposal-button"
                      style={{ width: "150px" }}
                      data-toggle="modal"
                      data-target={
                        organisation && !organisation.abn
                          ? "#modal-abn"
                          : "#modal-org-invoice"
                      }
                    >
                      Download PDF
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-secondary new-proposal-button"
                      style={{ width: "150px" }}
                      onClick={this.downloadPdfHandler}
                    >
                      Download PDF
                    </button>
                  )}

                  {selectedInvoice.status !== "Archived" &&
                    selectedInvoice.status !== "Paid" &&
                    (organisation && !organisation.abn ? (
                      <button
                        type="button"
                        className="btn btn-secondary new-proposal-button "
                        style={{ width: "150px" }}
                        data-toggle="modal"
                        data-target="#modal-abn"
                      >
                        Mark As Paid
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-secondary new-proposal-button "
                        style={{ width: "150px" }}
                        data-toggle="modal"
                        data-target="#modal-org-markAsPaid"
                      >
                        Mark As Paid
                      </button>
                    ))}
                  {selectedInvoice.status === "Paid" &&
                    selectedInvoice.status !== "Archived" && (
                      <button
                        type="button"
                        className="btn btn-secondary new-proposal-button"
                        style={{ width: "150px" }}
                        onClick={this.markAsUnpaidHandler}
                      >
                        Mark As Unpaid
                      </button>
                    )}
                  {selectedInvoice.status === "Paid" ||
                  selectedInvoice.status === "Archived" ||
                  selectedInvoice.status === "Plan" ||
                  selectedInvoice.status === "Later" ? null : (
                    <button
                      type="button"
                      className="btn btn-danger new-proposal-button"
                      style={{ width: "150px" }}
                      onClick={this.changeStatusToArchived}
                    >
                      Void
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.payment_plan && 
          this.state.payment_plan.status !== "Declined" && this.state.payment_plan.status !== "Cancelled" && (
            <div class="row">
              <div class="col-lg-12 mt-5">
                <div class="card">
                  <div class="card-body">
                    <h5>Payment Plan</h5>
                    <br />
                    <div class="invoice-table">
                      <table class="table table-bordered table-responsive-md text-center">
                        <thead>
                          <tr>
                            <th class="text-center">Number</th>
                            <th class="text-center">Type</th>
                            <th class="text-center">Date</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Amount</th>
                            <th class="text-center">Balance</th>
                          </tr>
                        </thead>
                        {this.renderTable()}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        <div className="modal fade" id="modal-org-markAsPaid">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Mark As Paid</h5>
                <button type="button" className="close" data-dismiss="modal">
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="">
                    <div className="row">
                      <div class="col-md-6 col-sm-12" id="hide-content">
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div className="input-group-text filter-span date-span">
                              Paid Date
                            </div>
                          </div>

                          <DatePicker
                            className="form-control date-picker-mark-as-read"
                            selected={this.state.paid_date}
                            onChange={this.handlePaidDate}
                            dateFormat={"dd/MM/yyyy"}
                          />
                          {this.state.markAsPaidError && (
                            <span style={{ color: "red" }}>
                              {this.state.markAsPaidError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div className="invoice-buttons text-center">
                <button
                  type="button"
                  onClick={this.handleMarkAsPaid}
                  className="btn btn-success"
                  style={{ width: "150px" }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal-org-invoice">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Send Invoice</h5>
                <button type="button" className="close" data-dismiss="modal">
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="">
                    <div className="row">
                      <div className="col-md-10">
                        <label className="sr-only" for="inlineFormInputGroup">
                          Contact
                        </label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">Name</div>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            onChange={this.nameChangeHandler}
                            id="inlineFormInputGroup"
                            placeholder=""
                            value={this.state.name}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10">
                        <label className="sr-only" for="inlineFormInputGroup">
                          Contact
                        </label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">Email</div>
                          </div>
                          <input
                            onChange={this.handleInputEmail.bind(this)}
                            className={
                              "form-control form-non-edit-modal " +
                              (this.state.email_err ? "border_red" : null)
                            }
                            value={this.state.email}
                            id="inlineFormInputGroup"
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.email_correct_err ? (
                      <div className="correct_email_err">
                        <span className="invoice-error text-danger">
                          Enter correnct email
                        </span>
                      </div>
                    ) : null}
                    {/* <label className="form-non-edit-label">Message</label> */}
                    <br />
                    <div className="form-group">
                      <div>Hi {this.state.name},</div>
                      <br />
                      {/* <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="10"
                        onChange={this.handleInputMessage.bind(this)}
                        value={this.state.message ? this.state.message : ""}
                      ></textarea> */}

                      {this.state.message && (
                        <DraftComponent
                          initialValue={
                            this.state.message && this.state.message
                          }
                          getValue={this.editorValue}
                        />
                      )}

                      <br />
                      {this.state.organisation &&
                      this.state.organisation.signature ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state.organisation &&
                              this.state.organisation.signature &&
                              this.state.organisation.signature.replace(
                                /\\n/g,
                                "<br />"
                              ),
                          }}
                        />
                      ) : (
                        <div>
                          Kind Regards
                          <br />
                          <br /> [organisation_name] <br />
                          <br />
                          [email] <br />
                          <br />
                          [phone]
                        </div>
                      )}
                    </div>

                    <div className="form-group form-group-modal-check flex-row flex-column">
                      <div className="before-checkbox"></div>
                      {/* <div className="custom-control custom-checkbox ">
                        <input
                          onChange={this.handlePdf.bind(this)}
                          name="pdf"
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck2"
                        />
                        <label
                          className="custom-control-label custom-control-invoice label-invoice"
                          htmlFor="customCheck2"
                        >
                          Include PDF attachment
                        </label>
                      </div> */}
                      {this.state.organisation &&
                        this.state.organisation.stripe_customer_id && (
                          <>
                            <div className="row">
                              <div className="col-md-10">
                                <label
                                  className="sr-only"
                                  for="inlineFormInputGroup"
                                >
                                  Contact
                                </label>
                                <div className="input-group mb-2">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      Mobile
                                    </div>
                                  </div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="inlineFormInputGroup"
                                    onChange={this.onMobileChangeHandler}
                                    placeholder=""
                                    value={this.state.mobile}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="custom-control custom-checkbox">
                              <input
                                onChange={this.handleSms.bind(this)}
                                name="sms"
                                type="checkbox"
                                class="form-check-input"
                                id="exampleCheck1"
                              />
                              <label
                                class="form-check-label"
                                htmlFor="exampleCheck1"
                              >
                                Send invoice to mobile
                              </label>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div className="invoice-buttons text-center">
                <button
                  type="button"
                  onClick={this.handleSendInvoice.bind(this)}
                  className="btn btn-success"
                  style={{ width: "150px" }}
                >
                  Send
                </button>
                <button
                  onClick={this.handleShowModal}
                  type="button"
                  data-dismiss="modal"
                  style={{ width: "150px" }}
                  className="btn btn-secondary ml-2"
                >
                  Cancel
                </button>
              </div>
              {/* <div
                className="modal-footer"
                style={{
                  borderTop: "1px #e9ecef",
                  padding: "2rem",
                  margin: "auto",
                }}
              >
                <button
                  onClick={this.handleSendInvoice.bind(this)}
                  type="button"
                  className="btn btn-secondary"
                >
                  Send
                </button> */}
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal-abn">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Enter Business ABN</h5>
                <button type="button" className="close" data-dismiss="modal">
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container abn">
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          To send invoices and proposals, the Business Abn is
                          required
                        </label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div
                              className="input-group-text"
                              style={{ width: "100px" }}
                            >
                              Business ABN
                            </div>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            onChange={this.abnChangeHandler}
                            id="inlineFormInputGroup"
                            placeholder=""
                            value={this.state.abn}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div className="invoice-buttons text-center">
                <button
                  type="button"
                  onClick={this.handleAbnSave.bind(this)}
                  className="btn btn-success"
                  style={{ width: "150px" }}
                >
                  Save
                </button>
                <button
                  onClick={this.handleShowModal}
                  type="button"
                  data-dismiss="modal"
                  style={{ width: "150px" }}
                  className="btn btn-secondary ml-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { selectedContact } = InvoiceActions;

const mapDispatchToProps = { selectedContact };

const mapStateToProps = (state) => {
  return {
    selectedInvoice: state.Invoices.invoice,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NonEditInvoice);
