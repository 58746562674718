import React from "react";
var NumberFormat = require("react-number-format");

export const plans = [
  {
    label:
      "30 days trial",
    value: "Trial",
  },
  {
    label:
      "$0/month Freemium Subscription - includes 5 invoices or proposals a month, payment gateway* and SMS communication*.",
    value: "Freemium",
  },
  {
    label:
      "$29+GST/month Standard Subscription - includes unlimited invoices and proposals, payment gateway* and SMS communication*.",
    value: "Standard",
  },
];

export const formatTwoDecimals = (value) => {
  return !isNaN(parseFloat(value)) && parseFloat(value).toFixed(2);
};

export const formatTwoDecimalsWithComma = (num) => {
  return !isNaN(parseFloat(num)) && parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formattedAmount = (number, prefix, toFix) => {
  return (
    <NumberFormat
      value={toFix ? parseInt(number).toFixed(toFix) : parseFloat(number)}
      displayType={"text"}
      thousandSeparator={true}
      prefix={prefix}
      renderText={(value) => <span>{value}</span>}
    />
  );
};

export const calculateGstHelper = (net, gst_free) => {
  let calculatedGst;
  if (parseInt(gst_free) === 1) {
    //There is no gst on this product
    calculatedGst = 0;
  } else if (parseInt(gst_free) === 0) {
    //10% GST is applicable on this item
    calculatedGst = (net * 10) / 100;
  }

  return calculatedGst;
};

export const renderTaxType = (gst_free) => {
  let type = "";
  if (parseInt(gst_free) === 1) {
    //There is no gst on this product
    type = "GST Free";
  } else if (parseInt(gst_free) === 0) {
    //10% GST is applicable on this item
    type = "GST";
  }

  return type;
};

export const calcTwoDecimalPlaces = (theform) => {
  if (!theform) {
    return;
  }
  var num = theform;
  let rounded = theform.rounded;
  var with2Decimals =
    num.toString().match(/^-?\d+(?:\.\d{0,2})?/) &&
    num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  rounded = with2Decimals;

  return rounded;
};
