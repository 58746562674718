import React, { Component } from "react";
import { connect } from "react-redux";
import NotificationsForm from "./Notifications";
import FlowActions from "../../../../redux/flow/actions";

const parseValue = (field) => {
  return parseInt(field) === 1 ? true : false;
};

class NotificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    document.title = "Cloud Collect - Notifications";
    const { fetchFlow } = this.props;

    fetchFlow();
  };

  handleSubmit = (values) => {
    const { updateNotificationSettings } = this.props;

    let payload = {};
    for (let key in values) {
      payload[key] = values[key] === true ? 1 : 0;
    }

    updateNotificationSettings({ payload });
  };

  render() {
    const { flows } = this.props;

    return (
      <NotificationsForm
        onSubmit={this.handleSubmit}
        initialValues={
          flows && {
            invoice_payment_notification: parseValue(
              flows.invoice_payment_notification
            ),
            proposal_accepted_notification: parseValue(
              flows.proposal_accepted_notification
            ),
            portal_submission_notification: parseValue(
              flows.portal_submission_notification
            ),
          }
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return { form: state.form, flows: state.Flow.flow };
};

const { fetchFlow, updateNotificationSettings } = FlowActions;

const mapDispatchToProps = { fetchFlow, updateNotificationSettings };

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
